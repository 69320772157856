export default function Tabs(props) {
  return (
    <div className="mb-4 border-b border-gray-200">
      <ul
        className="flex flex-wrap -mb-px text-sm font-medium text-center"
        id="myTab"
        role="tablist"
      >
        {props.tabitem.map((item, index) => {
          return (
            <li key={item} className="mr-2">
              <button
                className={
                  props.activeTab === item
                    ? "inline-block p-4 rounded-t-lg border-b-2 border-indigo-500 text-indigo-600"
                    : "inline-block p-4 rounded-t-lg border-b-2 border-transparent"
                }
                type="button"
                onClick={() => props.onTabClick(item, index)}
              >
                {item}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
