import React from "react";
import { Chart } from "react-charts";
import { numFormatter } from "../utils/index";

export default function Charts({ chartData = [], tooltipFormatterIsTon }) {
  const data = React.useMemo(() => {
    if (chartData.length)
      return chartData.map((dataLines, dataIndex) => {
        dataLines.data = dataLines.data.map((lineItem) => {
          return {
            date: new Date(
              new Date(lineItem.date - 1 + 1).setHours(5, 30, 0, 0)
            ),
            unit: lineItem.unit,
          };
        });
        return dataLines;
      });
  }, [chartData]);
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (data) => {
        // data.dataType = "time"
        return data.date;
      },
      elementType: "line",
      showGrid: false,

      // axisFamily: "time",
    }),
    []
  );
  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (data) => {
          // data.dataType = "time"
          return data.unit;
        },
        formatters: {
          scale: (value) => {
            return numFormatter(value);
          },
          tooltip: (value) => tooltipFormatterIsTon ? `Rs. ${value}/ton` : value

        },
        elementType: "line",
        showGrid: false,
        // curve: () => { return 0.5 }
      },
    ],
    []
  );
  // const tooltip = React.useMemo(
  //   () => ({
  //     // formatSecondary: (d) => { console.log(d); d.toFixed(1) },
  //     // render: (a) => {
  //     //   console.log(a);
  //     //   return a;
  //     // },
  //     focusedDatum: (d) => { console.log(d) }
  //   }),
  //   [],
  // );
  return (
    <div className="w-full h-full">
      <Chart
        datatype="time"
        options={{
          data, //: testData,
          primaryAxis,
          secondaryAxes,
          // tooltip: tooltip
        }}
      // tooltip={tooltip}
      />{" "}
    </div>
  );
}
