import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import usePrevious from "../../utils/usePrevious";
import Dropdown from "../../components/Dropdown";
import Tabs from "../../components/Tabs";
import FlatGlass from "./Flatglass";
import LargeFlatGlass from "./LargeFlatGlass";
import SmallGlass from "./SmallGlass";
import LargeGlassBottle from "./LargeGlassBottle.js";
import { STAKEHOLDER_DETAILS } from "./graphql";
import { numFormatter } from "../../utils";
import moment from "moment";

export default function Stakeholders() {
  const [material, setMaterial] = useState("");
  const [price, setPrice] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);
  const [activeTab, setActiveTab] = useState("Flat Glass Retailers");
  const [startDate, setStartdate] = useState(
    moment().add(-29, "days").format("YYYY-MM-DD")
  );
  const endDate = moment().format("YYYY-MM-DD");
  const [selectedOption, setSelectedOption] = useState({
    value: "30",
    label: "Last 30 Days",
  });
  const menuitem = [
    { value: "7", label: "Last 7 Days" },
    { value: "30", label: "Last 30 Days" },
  ];
  const tabitem = [
    "Flat Glass Retailers",
    "Large Flat Glass Aggregators (L2)",
    "Small Glass Bottle Aggregators (L1)",
    "Large Glass Bottle Aggregators (L2)",
  ];
  const previous = usePrevious(selectedOption);
  const previousTab = usePrevious(activeTab);

  const [getStakedetails, { data: stakeholderDetails }] =
    useLazyQuery(STAKEHOLDER_DETAILS);

  const getDetails = (id) => {
    getStakedetails({
      variables: {
        classificationID: id,
        startDate: startDate,
        endDate: endDate,
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    getDetails(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDetails(tabIndex);
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (
      stakeholderDetails &&
      stakeholderDetails.classifications[0].id === tabIndex
    ) {
      let object = JSON.parse(
        JSON.stringify(
          stakeholderDetails.classifications[0].materials_x_classifications
        )
      );

      object.forEach((item, i) => {
        const myArray = item.material.updates;

        const sortedarray = myArray.sort((a, b) =>
          b.updated_at.localeCompare(a.updated_at)
        );

        const arrayUniqueByKey = sortedarray.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.date === value.date && t.company_id === value.company_id
            )
        );

        const sortedbyDate = arrayUniqueByKey.sort((a, b) =>
          moment.utc(b.date).diff(moment.utc(a.date))
        );

        const newarray = sortedbyDate.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.company_id === value.company_id)
        );

        item.material.updates = newarray;
        item.materialName = item?.material?.name;

        let quantity = newarray.reduce((total, currentValue) => {
          return (total = total + currentValue.quantity);
        }, 0);
        let total_price = newarray.reduce((total, currentValue) => {
          return (total = total + currentValue.price * currentValue.quantity);
        }, 0);
        item.total_price = total_price;
        let price = total_price !== 0 ? total_price / quantity : total_price;
        let newvolume =
          quantity !== 0
            ? quantity % 1 !== 0
              ? `${
                  numFormatter(quantity)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,3})?/)[0]
                } MT/Month`
              : `${numFormatter(quantity)} MT/Month `
            : "-";
        item.volume = newvolume;
        item.price = tabIndex === 1 || tabIndex === 2 ? price * 1000 : price;
        item.volumecopy = quantity;

        item.avgPrice =
          price !== 0
            ? tabIndex === 1 || tabIndex === 2
              ? `₹ ${numFormatter((price * 1000).toFixed(2))}`
              : `₹ ${numFormatter(price.toFixed(2))}`
            : "-";
        item.volumeProcured = "-";
      });
      const materialsum = object.reduce((total, currentValue) => {
        return (total = total + currentValue.volumecopy);
      }, 0);
      const materialAvailable =
        materialsum === 0
          ? "-"
          : materialsum % 1 !== 0
          ? `${numFormatter(materialsum.toFixed(2))} MT`
          : `${numFormatter(materialsum)} MT`;
      const priceSum = object.reduce((total, currentValue) => {
        return (total = total + currentValue.total_price);
      }, 0);
      const totalPrice =
        tabIndex === 1 || tabIndex === 2
          ? (priceSum / materialsum) * 1000
          : priceSum / materialsum;
      const averagePrice =
        priceSum === 0 ? "-" : `₹ ${numFormatter(totalPrice.toFixed(2))}`;

      setMaterial(materialAvailable);
      setPrice(averagePrice);
      setTableData(object);
    }
  }, [stakeholderDetails, tabIndex, activeTab]);

  const onTabClick = (value, index) => {
    if (value !== previousTab) {
      setTableData([]);
    }
    setActiveTab(value);
    setTabIndex(index + 1);
    getDetails(index + 1);
  };

  const handleChange = (selectedOption) => {
    const date = selectedOption.value - 1;
    if (previous.value !== selectedOption.value) {
      setTableData([]);
    }
    setStartdate(moment().add(-date, "days").format("YYYY-MM-DD"));
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-gray-900 font-medium text-lg float-left">
          Stakeholder Details
        </div>
        <div className="float-right items-center px-4 py-2">
          <Dropdown
            placeholder="Duration"
            menuitem={menuitem}
            handleChange={handleChange}
            selectedOption={selectedOption}
          />
        </div>
        <div className="clear-both"></div>
      </div>
      <Tabs tabitem={tabitem} onTabClick={onTabClick} activeTab={activeTab} />
      {tabIndex === 1 && (
        <FlatGlass tableData={tableData} material={material} price={price} />
      )}
      {tabIndex === 2 && (
        <LargeFlatGlass
          tableData={tableData}
          material={material}
          price={price}
        />
      )}
      {tabIndex === 3 && (
        <SmallGlass tableData={tableData} material={material} price={price} />
      )}
      {tabIndex === 4 && (
        <LargeGlassBottle
          tableData={tableData}
          material={material}
          price={price}
        />
      )}
    </>
  );
}
