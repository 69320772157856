import { gql } from "@apollo/client";

export const MAPPED_USERS = gql`
  query MAPPED_USERS {
    FlatGlass: companies_aggregate(
      where: {
        updates: {
          material: {
            materials_x_classifications: { classification: { id: { _eq: 1 } } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    LargeFlatGlass: companies_aggregate(
      where: {
        updates: {
          material: {
            materials_x_classifications: { classification: { id: { _eq: 2 } } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    smallGlass: companies_aggregate(
      where: {
        updates: {
          material: {
            materials_x_classifications: { classification: { id: { _eq: 3 } } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    LargeGlass: companies_aggregate(
      where: {
        updates: {
          material: {
            materials_x_classifications: { classification: { id: { _eq: 4 } } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const STAKEHOLDER_DETAILS = gql`
  query STAKEHOLDER_DETAILS(
    $classificationID: Int!
    $startDate: date
    $endDate: date
  ) {
    classifications(where: { id: { _eq: $classificationID } }) {
      id
      materials_x_classifications {
        material {
          id
          name
          updates(
            where: { date: { _gte: $startDate, _lte: $endDate } }
            order_by: { date: desc }
          ) {
            price
            quantity
            date
            company_id
            updated_at
            materials_id
            type_quantity
          }
        }
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query GET_NOTIFICATION {
    notifications(
      order_by: { updated_at: desc }
      where: { status: { _in: ["In process", "Complete"] } }
    ) {
      company {
        name
        created_at
      }
      notification
      status
      comment
      created_at
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NOTIFICATION_SUBSCRIPTION {
    notifications_aggregate(where: { status: { _eq: "In process" } }) {
      aggregate {
        count
      }
    }
  }
`;
