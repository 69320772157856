import { useLazyQuery, useQuery } from "@apollo/client";
import { notification, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Charts from "../../components/Charts";
import Dropdown from "../../components/Dropdown";
import { GET_CLASSIFICATIONS, GET_CLASSIFICATION_BY_ID } from "./graphql";

const durationOptions = [
  { value: "Last 7 Days", label: "Last 7 Days" },
  { value: "Last 30 Days", label: "Last 30 Days" },
];

export default function VolumeTracker() {
  const [selectedClassification, setSelectedClassification] =
    React.useState(undefined);
  const [selectedDuration, setSelectedDuration] = React.useState({
    value: "Last 30 Days",
    label: "Last 30 Days",
  });
  const [volumeData, setVolumeData] = useState([
    { label: "", data: [{ date: "", unit: 0 }] },
  ]);
  const [stakeholderOptions, setStakeholderOptions] = useState([]);
  const [tickValues, setTickValues] = useState([]);
  const generateTickValues = () => {
    let weekendObj = [];
    let weekend = moment().endOf("week").valueOf();
    weekendObj.push(weekend);
    let dateUpto = 4;
    if (selectedDuration.value === "Last 30 Days") {
      dateUpto = 4;
    } else {
      dateUpto = 7;
    }

    for (let count = 0; count < dateUpto; count++) {
      weekend = moment(weekend).subtract(dateUpto === 4 ? 7 : 1, "days");
      weekendObj.push(weekend.valueOf());
    }
    setTickValues(weekendObj.reverse());
  };
  useEffect(() => {
    generateTickValues();
  }, [selectedDuration]);

  const [getClassificationData, { loading: gettingClassification }] =
    useLazyQuery(GET_CLASSIFICATION_BY_ID, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.classifications && data.classifications.length > 0) {
          let volumeArr = [];
          let dateRange = [];
          if (selectedDuration.value === "Last 30 Days") {
            dateRange.push(
              moment().subtract(29, "days").startOf("week").valueOf()
            );
            dateRange.push(moment().endOf("week").valueOf());
          } else {
            dateRange.push(moment().subtract(7, "days").valueOf());
            dateRange.push(moment().endOf("week").valueOf());
          }
          (data.classifications || []).map((classifi) => {
            let classification = classifi;
            let chartData = (classification.materials_x_classifications || [])
              .map((m) => {
                if (m.material.updates && m.material.updates.length) {
                  let dateWiseFilterGrp = {};
                  (m.material.updates || []).map((update) => {
                    if (!dateWiseFilterGrp[update.date]) {
                      dateWiseFilterGrp[update.date] = []
                    }
                    dateWiseFilterGrp[update.date].push(update)
                  });
                  // console.log(dateWiseFilterGrp)
                  let sortedDateGrp = {}
                  Object.keys(dateWiseFilterGrp)
                    .sort().map((data) => {
                      sortedDateGrp[data] = dateWiseFilterGrp[data]
                    }).reverse()
                  let cmpyBasedGrp = {}, quantityDataSet = [
                    {
                      date: moment(dateRange[0]).valueOf(),
                      unit: 0
                    }
                  ] //filter the cmpy based updates
                  Object.keys(sortedDateGrp).map((dateKey) => {
                    let cmpyWiseFilter = {}
                    sortedDateGrp[dateKey].map((dateObj) => {
                      if (cmpyWiseFilter[dateObj.company_id]) {
                        if (
                          moment(cmpyWiseFilter[dateObj.company_id]).isAfter(
                            dateObj.updated_at
                          )
                        ) {
                          cmpyWiseFilter[dateObj.company_id] = dateObj;
                        }
                      } else {
                        cmpyWiseFilter[dateObj.company_id] = dateObj;
                      }
                    })

                    if (!cmpyBasedGrp[dateKey]) {
                      cmpyBasedGrp[dateKey] = {}
                    }
                    cmpyBasedGrp[dateKey] = (Object.values(cmpyWiseFilter))
                    let dateWiseQuantity = Object.values(cmpyWiseFilter)
                      .reduce((a, b) => ({
                        ...a,
                        quantity: a.quantity + b.quantity,
                      }));
                    quantityDataSet.push({
                      date: moment(dateKey).valueOf(),
                      dateJ: dateKey,
                      unit: parseFloat(dateWiseQuantity.quantity.toFixed(2))
                    })
                  })

                  return ({
                    label: m.material.name,
                    data: quantityDataSet,
                  });
                } else {
                  return undefined;
                }
              })
              .filter((cData) => cData);
            if (chartData.length) {
              chartData.map((cData) => {
                // let nonZeroData = cData.data.filter((point) => point.unit > 0);
                volumeArr.push({
                  label: cData.label,
                  data: cData.data,
                });
              });
            }
          });
          if (volumeArr.length > 0) setVolumeData(volumeArr);
          else setVolumeData([{ label: "", data: [{ date: "", unit: 0 }] }]);
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    });
  const { loading: gettingAllClassifications } = useQuery(GET_CLASSIFICATIONS, {
    onCompleted: (data) => {
      let durationObj = {};
      if (selectedDuration) {
        if (selectedDuration.value === "Last 7 Days") {
          let startDate = moment().subtract(6, "days").startOf("day");
          let endDate = moment().endOf("day");
          durationObj = { date: { _gt: startDate, _lte: endDate } };
        } else if (selectedDuration.value === "Last 30 Days") {
          let startDate = moment().subtract(29, "days").startOf("day");
          let endDate = moment().endOf("day");
          durationObj = { date: { _gt: startDate, _lte: endDate } };
        }
      } else {
        let startDate = moment()
          .endOf("week")
          .subtract(12, "weeks")
          .startOf("day");
        let endDate = moment().endOf("week");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      }
      if (data && data.classifications && data.classifications.length) {
        let stakeOpt = data.classifications.map((c, cIndex) => {
          return {
            label: c.name,
            value: c.id,
          };
        });
        setStakeholderOptions(stakeOpt);
        setSelectedClassification(stakeOpt[0] || undefined);

        getClassificationData({
          variables: {
            id: [stakeOpt[0]?.value || undefined],
            dateCond: durationObj,
          },
        });
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });
  useEffect(() => {
    let durationObj = {};
    let stakeHoldersArr = [];
    if (selectedDuration) {
      if (selectedDuration.value === "Last 7 Days") {
        let startDate = moment().subtract(6, "days").startOf("day");
        let endDate = moment().endOf("day");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      } else if (selectedDuration.value === "Last 30 Days") {
        let startDate = moment().subtract(29, "days").startOf("day");
        let endDate = moment().endOf("day");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      }
    } else {
      let startDate = moment()
        .endOf("week")
        .subtract(12, "weeks")
        .startOf("day");
      let endDate = moment().endOf("week");
      durationObj = { date: { _gt: startDate, _lte: endDate } };
    }
    if (selectedClassification?.value) {
      getClassificationData({
        variables: {
          id: [selectedClassification?.value],
          dateCond: durationObj,
        },
      });
    } else if (stakeholderOptions.length) {
      stakeholderOptions.map((stakeHolder) => {
        stakeHoldersArr.push(stakeHolder.value);
      });
      getClassificationData({
        variables: {
          id: stakeHoldersArr,
          dateCond: durationObj,
        },
      });
    } else if (!selectedClassification) {
      setVolumeData([{ label: "", data: [{ date: "", unit: 0 }] }]);
    }
  }, [stakeholderOptions, selectedClassification, selectedDuration]);

  // useEffect(() => {
  //   setSelectedDuration(durationOptions[1]);
  // }, []);

  return (
    <Spin spinning={gettingAllClassifications || gettingClassification}>
      <div className="font-sans">
        <div className="flex justify-between">
          <div className=" flex gap-5">
            <div className="text-black text-2xl font-bold">Volume Tracker</div>
            <div className="flex gap-2 ">
              <Dropdown
                placeholder="Select Stakeholder"
                menuitem={stakeholderOptions}
                sizeClassName=" w-52 "
                handleChange={(classification) => {
                  setSelectedClassification(classification || undefined);
                }}
                selectedOption={selectedClassification}
              />
            </div>
          </div>
          <div>
            <Dropdown
              placeholder="Duration"
              menuitem={durationOptions}
              sizeClassName="w-36"
              handleChange={(duration) => {
                setSelectedDuration(duration || undefined);
              }}
              selectedOption={selectedDuration}
            />
          </div>
        </div>
        <div className=" mt-10 px-10 py-16 bg-white rounded-lg h-80vh">
          <Charts chartData={volumeData} tickValues={tickValues} />
        </div>
      </div>
    </Spin>
  );
}
