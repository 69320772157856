import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import kcLogo from "../../assets/kclogo.svg";
import logo from "../../assets/logo.svg";

function Login() {
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [validEmail, setValidEmail] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checkemail, setCheckemail] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);

  useEffect(() => {
    if (localStorage.token) {
      let role = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      if (role === "kc") {
        history.push("/uploadData");
      } else {
        history.push("/dashboard");
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "admin") {
      document.querySelector("title").textContent = "Kabadiwalla Connect";
      document.querySelector("link[rel~='icon']").href = kcLogo;
    }
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(email);
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.length === 0) {
      setCheckPassword(false);
      setCheckemail(true);
      setValidEmail(false);
      setError("");
    } else if (password.length === 0) {
      setCheckemail(false);
      setCheckPassword(true);
      setValidEmail(false);
      setError("");
    } else if (!validateEmail(username)) {
      setValidEmail(true);
      setCheckemail(false);
      setCheckPassword(false);
      setError("");
    } else {
      setCheckemail(false);
      setCheckPassword(false);
      setValidEmail(false);
      setError("");
      const query = {
        query: `
          mutation Login($email: String!, $password: String!) {
            login(email:  $email, password:$password) {
              token
            }
          }

        `,
        variables: {
          email: username,
          password: password,
        },
      };
      try {
        fetch(`${process.env.REACT_APP_API}`, {
          method: "POST",
          body: JSON.stringify(query),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
          })
          .then((json) => {
            if (json?.data?.login?.token) {
              localStorage.setItem("token", json?.data?.login?.token);
              let role = jwtDecode(localStorage.getItem("token"))[
                "https://hasura.io/jwt/claims"
              ]["x-hasura-default-role"];
              if (
                role === "kc" &&
                location.pathname.split("/")[1] === "admin"
              ) {
                history.push("/uploadData");
              } else if (
                role === "sg" &&
                location.pathname.split("/")[1] !== "admin"
              ) {
                window.location.href = "/dashboard";
              } else {
                setError("Login failed. Wrong email address or password");
              }
            } else if (json?.errors) {
              setError("Login failed. Wrong email address or password");
            }
          });
      } catch (err) {
        setError("Login failed. Wrong email address or password");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="px-8 py-6 text-left bg-white shadow-lg w-128">
        <div className="flex justify-center items-center">
          {location.pathname.split("/")[1] === "admin" ? (
            <img alt="kc" src={kcLogo}></img>
          ) : (
            <img alt="logo" src={logo}></img>
          )}
        </div>
        <h3 className="text-2xl font-semibold text-center text-gray-900">
          Log in to your account
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            {error ? <p className="text-bgred text-center">{error}</p> : null}
            <div>
              <label className="block text-gray-700">Username</label>
              <input
                type="text"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                onChange={(e) => setUsername(e.target.value)}
              />

              {checkemail && (
                <span className="text-xs tracking-wide text-red-600">
                  Username field is required{" "}
                </span>
              )}
              {validEmail && (
                <span className="text-xs tracking-wide text-red-600">
                  Enter a valid Email
                </span>
              )}
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                onChange={(e) => setPassword(e.target.value)}
              />
              {checkPassword && (
                <span className="text-xs tracking-wide text-red-600">
                  Password field is required{" "}
                </span>
              )}
            </div>
            <div className="flex items-baseline justify-between">
              <button className="px-6 py-2 mt-4 text-white bg-indigo-600 rounded-lg w-full">
                Log in
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
