import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Upload } from "antd";
import { INSERT_FILES } from "./graphql";
import { getS3UploadUrl, uploadFiles } from "../../utils";
import addImage from "../../assets/addImage.svg";

const { Dragger } = Upload;

export default function MyDropzone(props) {
  const { fileStatus, type, imageFileStatus } = props;
  const [files, setFiles] = useState(null);
  const [fileURL, setFileUrl] = useState();
  const [insertFiles] = useMutation(INSERT_FILES);

  useEffect(() => {
    if (fileURL) {
      insertFiles({
        variables: {
          fileUrl: fileURL,
          type: type,
        },
      }).then((res) => {
        if (res?.data?.insert_files?.returning[0]?.type === "images") {
          imageFileStatus("success");
        } else {
          fileStatus("success");
        }
      });
    }
    // eslint-disable-next-line
  }, [fileURL]);

  return (
    <>
      <Dragger
        name="file"
        accept={".csv"}
        multiple={false}
        showUploadList={false}
        beforeUpload={async (file) => {
          if (type === "images") {
            imageFileStatus("parse");
          } else {
            fileStatus("parse");
          }
          let signedURL = await getS3UploadUrl(file.name, "text/csv");
          if (signedURL[1].statuscode === 200) {
            let URLs = await uploadFiles([signedURL[0]], [file]);
            setFiles(file.name);
            setFileUrl(URLs[0]);
          } else {
            if (type === "images") {
              imageFileStatus("error");
            } else {
              fileStatus("error");
            }
          }
          return false;
        }}
      >
        <div className="w-full h-32 bg-white rounded-md border-dashed border-gray-300 border-2">
          <div className="flex flex-col items-center justify-center text-gray-600 font-medium text-sm h-32">
            <img
              src={addImage}
              alt="upload"
              style={{ width: "48px", height: "48px" }}
            />
            <p>
              <span className="text-indigo-600">Upload CSV file&nbsp;</span> or
              drag and drop here
            </p>
          </div>
        </div>
      </Dragger>
      <div className="text-gray-600 font-medium text-sm ">
        {files !== null && <> File name: {files}</>}
      </div>
    </>
  );
}
