import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "../utils";
import jwtDecode from "jwt-decode";
import ErrorBoundary from "../components/ErrorBoundary";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // eslint-disable-next-line
  const [role, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ]
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <>
            {role === rest.roles[0] ? (
              <Component {...props} />
            ) : (
              <ErrorBoundary />
            )}
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
