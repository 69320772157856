import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Input, Modal, notification, Spin } from "antd";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import BlueMarker from "../../assets/icons/bluePin.svg";
import Timer from "../../assets/icons/clock.svg";
import Mobile from "../../assets/icons/mobile.svg";
// import RedMarker from "../../assets/icons/redPin.svg";
// import SkyBlueMarker from "../../assets/icons/skyBluePin.svg";
// import YellowMarker from "../../assets/icons/yellowPin.svg";
import { useHistory } from "react-router";
import CustomTable from "../../components/CustomTable";
import Drawer from "../../components/Drawer";
import Dropdown from "../../components/Dropdown";
import Tabs from "../../components/Tabs";
import { numFormatter } from "../../utils/index";
import {
  Classification,
  COMPANIES,
  GET_COMPANIES,
  MATERIALS_LIST,
  SUBMIT_REQUEST,
} from "./graphql";
import InsightGallery from "./InsightGallery";
import InsightsMaterials from "./InsightsMaterials";

const materialMenuitem = [
  { value: "Last 7 Days", label: "Last 7 Days" },
  { value: "Last 30 Days", label: "Last 30 Days" },
];
const classificationMenuItem = [
  {
    label: "Flat Glass Retailers",
    value: 1,
  },
  { label: "Large Flat Glass Aggregators", value: 2 },
  {
    label: "Small Glass Bottle Aggregators",
    value: 3,
  },
  { label: "Large Glass Bottle Aggregators", value: 4 },
];

// const icons = [RedMarker, BlueMarker, YellowMarker, SkyBlueMarker];
const tabitem = ["Insights", "Gallery", "Update History"];
const { TextArea } = Input;
const UpdateHistory = ({ data = {} }) => {
  return (
    <div>
      {data.file !== null && (
        <div className="mb-7 flex text-sm font-medium text-gray3 gap-3">
          <div className="w-24 flex-shrink-0">
            {moment(data.file.created_at).format("DD MMM, YYYY")}
          </div>
          <div className="text-sm text-gray1">
            {data.file.type === "data" && "Material and Price details updated"}
            {data.file.type === "images" && "Gallery details updated"}
          </div>
        </div>
      )}
    </div>
  );
};

// const MarkerIcon = ({ color = "#EB5757" }) => {
//   return (
//     <svg
//       width="27"
//       height="32"
//       viewBox="0 0 27 32"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M27 13.5C27 20.9558 16 32 13.5 32C10.5 32 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5Z"
//         fill={color}
//       />
//       <circle cx="13.5" cy="12.5" r="5.5" fill="white" />
//     </svg>
//   );
// };

function Materials(props) {
  const history = useHistory();
  const [selectedClassification, setSelectedClassification] = React.useState(
    classificationMenuItem[
      new URLSearchParams(document.location.search).get("stakeid")
    ]
  );
  const [selectedMaterial, setSelectedMaterial] = React.useState(undefined);
  const [selectedDuration, setSelectedDuration] = React.useState(
    materialMenuitem[1]
  );
  const [selectedLocation, setSelectedLocation] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("Insights");
  const [isRequest, setIsRequest] = React.useState(false);
  const [changeRequesttext, setChangeRequesttext] = React.useState(false);
  const [materials, setMaterials] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  // eslint-disable-next-line
  const [limit, setLimit] = React.useState(20);
  const [materialDropDownVal, setMaterialDropDownVal] = React.useState([]);
  const [locationDropDownVal, setLocationDropDownVal] = React.useState([]);
  const [classificationDropDownVal, setClassificationDropDownVal] =
    React.useState([]);
  const [companyInfo, setCompanyInfo] = React.useState(undefined);
  const [materialsName, setMaterialsName] = React.useState([]);
  const [updateHistoryList, setUpdateHistoryList] = React.useState([]);
  // const [companyImages, setCompanyImages] = React.useState([{}]);
  const [searchTerm, setSearchTerm] = React.useState({});
  const [locationSeachTerm, setLocationSearchTerm] = React.useState({});
  const [requestMessage, setRequestMessage] = React.useState("");
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [bounds, setBounds] = React.useState(undefined);
  const [companies, setCompanies] = React.useState([]);
  const [selectedMarker, setSelectedMarker] = useState(undefined);
  const [lastModified, setLastModified] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  //const [selectedCompany, setSelectedCompany] = useState({});
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalData, setModalData] = useState({});

  const columns = useMemo(
    () => [
      {
        name: "Classification",
        key: "classification",
        render: (row, key) => {
          return (
            <div>
              <span
                className={`w-2.5 h-2.5 inline-block mr-3 rounded-full`}
                style={{
                  backgroundColor: row.colour || "#F2C94C",
                }}
              ></span>
              <span>{row.classification}</span>
            </div>
          );
        },
        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
      {
        name: "Name",
        key: "companyName",
        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
      {
        name: "Location",
        key: "location",

        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
      {
        name: "Total Volume",
        key: "totalVolume",
        render: (row, key) => {
          return <div>{`${row.newtotalVolume} ${row.volUnit}`}</div>;
        },
        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
      {
        name: "Avg price",
        key: "avgPrice",
        render: (row, key) => {
          return (
            <div>
              <span>
                {isNaN(row.newprice) ? `₹0` : `₹${row.newprice.toFixed(2)}`}
              </span>
            </div>
          );
        },
        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
      {
        name: "",
        key: "action",
        render: (row) => {
          return (
            <div
              className=" text-sm text-indigo-600 cursor-pointer "
              onClick={() => {
                setSelectedMarker(
                  String(row.colour || "#EB5757").replace("#", "%23")
                );
                let startDate = moment()
                  .endOf("week")
                  .subtract(12, "weeks")
                  .startOf("day");
                let endDate = moment().endOf("week");
                getCompanyInfo({
                  variables: {
                    id: row.companyId,
                    where: {
                      date: { _gt: startDate, _lte: endDate },
                      material: {
                        materials_x_classifications: {
                          classification_id: { _eq: row.classificationId },
                        },
                      },
                    },
                  },
                });
                setOpenDrawer(true);
              }}
            >
              Details
            </div>
          );
        },
        // className: "font-medium text-gray-500 tracking-wider text-sm"
      },
    ],
    []
  );
  const onTabClick = (value) => {
    setActiveTab(value);
  };

  // useEffect(() => {
  //   setSelectedDuration(materialMenuitem[1]);
  // }, []);

  useEffect(() => {
    getMaterialList({
      variables: {
        limit: limit,
        offset: offset,
        where: searchTerm,
        locationWhere: locationSeachTerm,
      },
    });
  }, [offset, limit, searchTerm, locationSeachTerm]);

  useEffect(() => {
    setOffset(0);
    let startDate, endDate, durationObj;
    if (selectedDuration?.value === "Last 7 Days") {
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
      durationObj = { updates: { date: { _gte: startDate, _lte: endDate } } };
    } else if (selectedDuration?.value === "Last 30 Days") {
      startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
      durationObj = { updates: { date: { _gte: startDate, _lte: endDate } } };
    } else {
      durationObj = {};
    }
    setSearchTerm({
      _and: [
        selectedMaterial
          ? {
              name: { _ilike: selectedMaterial },
            }
          : { name: {} },
        durationObj,
        selectedLocation.length > 0
          ? {
              updates: { company: { location: { _in: selectedLocation.a } } },
            }
          : { updates: {} },
        selectedClassification !== undefined
          ? {
              materials_x_classifications: {
                classification_id: { _eq: selectedClassification.value },
              },
            }
          : {
              materials_x_classifications: {},
            },
      ],
    });
    if (selectedLocation.length > 0)
      setLocationSearchTerm({
        company: { location: { _in: selectedLocation } },
      });
    else {
      setLocationSearchTerm({});
    }
  }, [
    selectedMaterial,
    selectedLocation,
    selectedDuration,
    selectedClassification,
  ]);

  const [getMaterialList, { loading: gettingMaterialsList }] = useLazyQuery(
    MATERIALS_LIST,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        let classificationObj = {};
        if (data && data.classifications.length > 0) {
          data.classifications.map((classification) => {
            classificationObj[classification.name] = {};
          });
        }
        if (offset > 0) {
          materials.map((material) => {
            if (!classificationObj[material.classification]) {
              classificationObj[material.classification] = {};
            }
            classificationObj[material.classification][material.companyName] =
              material;
          });
        }
        if (data && data.materialsTableData.length > 0) {
          let materialsArr = [];
          let bounds = new props.google.maps.LatLngBounds();
          let companyList = {};
          let object = JSON.parse(JSON.stringify(data.materialsTableData));
          object?.map((materialsData) => {
            if (materialsData.updates.length > 0) {
              let updatedInfo = materialsData.updates;
              updatedInfo = [...updatedInfo].sort((a, b) => {
                if (a.date < b.date)
                  moment.utc(b.updated_at).diff(moment.utc(a.updated_at));
              });
              let companyFiter = {};
              updatedInfo.map((update) => {
                if (!companyFiter[update.company.id]) {
                  companyFiter[update.company.id] = update;
                }
              });
              Object.values(companyFiter).map((update) => {
                if (!companyList[update.company.id]) {
                  let classifications = {};
                  classifications[
                    materialsData.materials_x_classifications[0]?.classification?.name
                  ] = {};
                  companyList[update.company.id] = {
                    ...update.company,
                    classifications,
                    color: String(
                      materialsData.materials_x_classifications[0]
                        ?.classification?.colour || "#EB5757"
                    ).replace("#", "%23"),
                  };
                } else {
                  let classifications =
                    companyList[update.company.id].classifications;
                  classifications[
                    materialsData.materials_x_classifications[0]?.classification?.name
                  ] = {};
                  companyList[update.company.id] = {
                    ...companyList[update.company.id],
                    classifications,
                  };
                }
                if (
                  !classificationObj[
                    materialsData.materials_x_classifications[0].classification
                      .name
                  ][update.company.name]
                ) {
                  classificationObj[
                    materialsData.materials_x_classifications[0].classification.name
                  ][update.company.name] = {
                    classification:
                      materialsData.materials_x_classifications[0]
                        .classification.name,
                    classificationId:
                      materialsData.materials_x_classifications[0]
                        .classification.id,
                    colour:
                      materialsData.materials_x_classifications[0]
                        .classification.colour,
                    name: materialsData.name,
                    companyId: update.company.id,
                    companyName: update.company.name,
                    location: update.company.location,
                    price: update.price * update.quantity,
                    totalVolume: update.quantity,
                    count: 1,
                    type_quantity: update.type_quantity,
                  };
                } else {
                  classificationObj[
                    materialsData.materials_x_classifications[0].classification
                      .name
                  ][update.company.name].count++;
                  classificationObj[
                    materialsData.materials_x_classifications[0].classification.name
                  ][update.company.name].price +=
                    update.price * update.quantity;
                  classificationObj[
                    materialsData.materials_x_classifications[0].classification.name
                  ][update.company.name].totalVolume += update.quantity;
                  classificationObj[
                    materialsData.materials_x_classifications[0].classification.name
                  ][update.company.name].type_quantity = update.type_quantity;
                }
              });
            }
          });
          Object.keys(classificationObj).map((object) => {
            Object.values(classificationObj[object]).forEach((val) => {
              materialsArr.push({
                ...val,
                newprice: numFormatter((val.price / val.totalVolume) * 1000),
                newtotalVolume: numFormatter(
                  parseFloat(val.totalVolume.toFixed(2))
                ),
                volUnit: "tons",
              });
            });
          });

          /*return {
                  classification:
                    materialsData.materials_x_classifications[0].classification
                      .name,
                  colour:
                    materialsData.materials_x_classifications[0].classification
                      .colour,
                  name: materialsData.name,
                  companyId: update.company.id,
                  companyName: update.company.name,
                  location: update.company.location,
                  price: update.price,
                  totalVolume:
                    update.type_quantity === "glass"
                      ? `${update.quantity} tons`
                      : `${update.quantity} pieces`,
                };*/

          /*if (materialsUpdArr.length > 0) {
                materialsArr = [...materialsUpdArr, ...materialsArr];
              }*/

          if (offset > 0) {
            // materialsArr = [...materials, ...materialsArr];
            companies.map((com, comIndex) => {
              companyList[com.id] = com;
            });
          }

          companyList = Object.keys(companyList).map((c) => {
            bounds.extend({
              lat: companyList[c].lat,
              lng: companyList[c].long,
            });
            return companyList[c];
          });
          setBounds(bounds);
          setCompanies(companyList);
          if (selectedClassification === undefined) {
            setMaterials(
              materialsArr.sort((m1, m2) => {
                let c1 = String(m1.companyName).toLowerCase();
                let c2 = String(m2.companyName).toLowerCase();
                return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
              })
            );
          } else {
            let companyListArr = companyList
              .map((company) => {
                let classifications = Object.keys(company.classifications);
                if (classifications.includes(selectedClassification.label)) {
                  return company;
                }
              })
              .filter((data) => data);
            setCompanies(companyListArr);
            let newArray = materialsArr.filter((item) => {
              return item.classification === selectedClassification.label;
            });
            setMaterials(
              newArray.sort((m1, m2) => {
                let c1 = String(m1.companyName).toLowerCase();
                let c2 = String(m2.companyName).toLowerCase();
                return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
              })
            );
          }
          if (data.materialsTableData.length < limit) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        } else {
          setMaterials([]);
        }

        if (data && data.materialsSelectData) {
          let materialDropDownSet = new Set();
          data.materialsSelectData.map((material) => {
            materialDropDownSet.add(material.name);
          });
          setMaterialDropDownVal(
            [...materialDropDownSet].map((material, index) => {
              return {
                value: material + index,
                label: material,
              };
            })
          );
          // let locationDropDownSet = new Set();
          // data.materialsSelectData.map((material) => {
          //   if (
          //     String(material.name).toLowerCase() ===
          //       String(selectedMaterial).toLowerCase() ||
          //     selectedMaterial === undefined
          //   ) {
          //     locationDropDownSet.add(material.updates[0]?.company?.location);
          //   }
          // });
          // setLocationDropDownVal(
          //   [...locationDropDownSet].map((location, index) => {
          //     return {
          //       value: location + index,
          //       label: location,
          //     };
          //   })
          // );
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const [getCompanyInfo, { loading: gettingCompanyInfo }] = useLazyQuery(
    COMPANIES,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.companies && data.companies.length) {
          setCompanyInfo(data.companies[0]);
          let materialNameArr = new Set();
          let newObject = [];
          data.companies[0]?.updates.map((mat) => {
            materialNameArr.add(mat.material.name);
          });

          setMaterialsName([...materialNameArr]);
          const currentDate = moment().add(-14, "days").format("YYYY-MM-DD");
          const updatedDate = moment(
            data.companies[0].updatesWithlastUpdatedRecord[0].date
          ).format("YYYY-MM-DD");
          setLastModified(moment(updatedDate).isSameOrBefore(currentDate));
          newObject = [
            ...data.companies[0].imageHistory,
            ...data.companies[0].dataHistory,
          ];
          newObject.sort((a, b) =>
            moment.utc(b.file_id).diff(moment.utc(a.file_id))
          );
          setUpdateHistoryList(newObject);
          // setCompanyImages(data.companies[0]?.images);
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const [submitRequest, { loading: submittingRequest }] = useMutation(
    SUBMIT_REQUEST,
    {
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );

  const { loading: gettingCompanyList } = useQuery(GET_COMPANIES, {
    onCompleted: (data) => {
      if (data.companies && data.companies.length) {
        let locationDropDownSet = new Set();
        data.companies.map((comp) => {
          locationDropDownSet.add(comp.location);
        });
        setLocationDropDownVal(
          [...locationDropDownSet].map((location) => {
            return {
              label: location,
              value: location,
            };
          })
        );
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const { loading: gettingClassification } = useQuery(Classification, {
    onCompleted: (data) => {
      if (data.classifications && data.classifications.length) {
        let stakeOpt = data.classifications.map((c, cIndex) => {
          return {
            label: c.name,
            value: c.id,
          };
        });
        setClassificationDropDownVal(stakeOpt);
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });
  // const { data } = useQuery(UPDATE_HISTORY, {
  //   onCompleted: (data) => {
  //     if (data.files && data.files.length) {
  //       setUpdateHistoryList(data.files);
  //     }
  //   },
  // });

  return (
    <Spin
      spinning={
        gettingCompanyInfo ||
        gettingCompanyList ||
        gettingMaterialsList ||
        submittingRequest ||
        gettingClassification
      }
    >
      <div className="font-sans">
        <div className="flex gap-5">
          <div className="text-black text-2xl font-bold">
            Material Availability
          </div>
          <div className="flex gap-2 ">
            <Dropdown
              allowClear={true}
              placeholder="Select Stakeholder"
              menuitem={classificationDropDownVal}
              sizeClassName="max-w-lg"
              handleChange={(classification) => {
                // console.log(classification);
                // classification === null
                //   ? history.replace("/materials")
                //   : history.replace(
                //       `/materials?stakeid=${classification.value}`
                //     );
                setSelectedClassification(classification || undefined);
              }}
              selectedOption={selectedClassification}
            />
            <Dropdown
              allowClear={true}
              placeholder="Select Material"
              menuitem={materialDropDownVal}
              sizeClassName="w-44"
              handleChange={(material) => {
                setSelectedMaterial(material?.label || undefined);
              }}
              //selectedOption={selectedMaterial}
            />

            <Dropdown
              placeholder="Duration"
              menuitem={materialMenuitem}
              sizeClassName="w-44"
              handleChange={(duration) => {
                setSelectedDuration(duration || undefined);
              }}
              selectedOption={selectedDuration}
            />

            <Dropdown
              allowClear={true}
              placeholder="Locations"
              menuitem={locationDropDownVal}
              sizeClassName={selectedLocation.length > 0 ? "max-w-lg" : "w-44"}
              multi={true}
              handleChange={(location) => {
                let locationArr = [];
                for (let loc of location) locationArr.push(loc.value);
                if (locationArr.length !== selectedLocation.length) {
                  setSelectedLocation(locationArr);
                }
              }}
              //selectedOption={selectedLocation}
            />
          </div>
        </div>
        <div>
          <div
            className={`mt-4 max-h-halfScreen overflow-scroll mb-5 pb-4  `}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={(materials || []).length}
              next={() => {
                setOffset(offset + limit);
              }}
              loader={<div></div>}
              hasMore={hasMoreData}
              endMessage={<p style={{ textAlign: "center" }}></p>}
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={10}
              scrollableTarget="scrollableDiv"
            >
              <CustomTable
                columns={columns}
                data={materials}
                boldColumnIndex={0}
                rowClass="font-medium text-gray-500 tracking-wider text-sm"
              />
            </InfiniteScroll>
          </div>
        </div>
        {!gettingMaterialsList && (
          <div
            className="max-h-halfScreen overflow-scroll "
            style={{ height: "39vh" }}
          >
            <div className="">
              <Map
                google={props.google}
                style={{ height: "39vh", maxWidth: "100%" }}
                containerStyle={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
                //initialCenter={{ lat: 40.854885, lng: -88.081807 }}
                bounds={bounds}
                onClick={() => {
                  /* if (selectedMarker) {
                  setSelectedMarker(undefined);
                  setSelectedCompany({});
                } */
                }}
              >
                {companies.map((company, companyIndex) => (
                  <Marker
                    key={companyIndex}
                    title={company.name}
                    name={company.address}
                    id={company.id}
                    position={{ lat: company.lat, lng: company.long }}
                    icon={{
                      url: `data:image/svg+xml,%3Csvg width='27' height='32' viewBox='0 0 27 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27 13.5C27 20.9558 16 32 13.5 32C10.5 32 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5Z' fill='${company.color}'/%3E%3Ccircle cx='13.5' cy='12.5' r='5.5' fill='white'/%3E%3C/svg%3E%0A`,
                      anchor: new props.google.maps.Point(0, 32),
                      scaledSize: new props.google.maps.Size(20, 20),
                    }}
                    onClick={(props, marker, e) => {
                      /* setSelectedMarker(marker);
                    setSelectedCompany(props); */

                      let startDate = moment()
                        .endOf("week")
                        .subtract(12, "weeks")
                        .startOf("day");
                      let endDate = moment().endOf("week");
                      getCompanyInfo({
                        variables: {
                          id: props.id,
                          where: { date: { _gt: startDate, _lte: endDate } },
                        },
                      });
                      setSelectedMarker(
                        String(company.color || "#EB5757").replace("#", "%23")
                      );
                      setOpenDrawer(true);
                    }}
                  />
                ))}
                {/* <InfoWindow
                marker={selectedMarker}
                visible={selectedMarker || false}
              >
                <div
                  className="font-bold text-normal cursor-pointer"
                  onClick={() => {
                    getCompanyInfo({
                      variables: {
                        id: selectedCompany.id,
                      },
                    });
                    setOpenDrawer(true);
                  }}
                >
                  <h1>{selectedCompany?.title}</h1>
                </div>
                <div>
                  <div className=" font-bold text-gray-900 w-10/12">
                    {companyInfo?.name}
                  </div>
                  <div className="flex items-center gap-2 mt-2 ">
                    <div className=" w-4">
                      <img src={Mobile} alt="mobile Icon" className="mx-auto" />
                    </div>
                    <div className=" text-sm text-gray-900">
                      {companyInfo?.phone}
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mt-2 ">
                    <div className=" w-4">
                      <img src={Timer} alt="timer Icon" className="mx-auto" />
                    </div>
                    <div
                      className={
                        lastModified
                          ? "text-sm text-bgred"
                          : " text-sm text-gray-900"
                      }
                    >
                      {"Last updated: "}
                      {moment(
                        companyInfo?.updatesWithlastUpdatedRecord[0]?.date
                      ).format("D MMM, YYYY")}
                    </div>
                <div className="text-xs">
                  <p>{selectedCompany?.name}</p>
                </div>
              </InfoWindow> */}
              </Map>
            </div>
          </div>
        )}
        {openDrawer && (
          <div>
            <Drawer
              prefixCls="drawer"
              open={openDrawer}
              toggleHandler={() => {
                setOpenDrawer(false);
              }}
              closeButton={
                <div className="py-6 px-8 font-sans relative">
                  <button
                    onClick={() => {
                      setIsRequest(false);
                      setOpenDrawer(false);
                      setChangeRequesttext(false);
                    }}
                    className=" right-6 top-4 absolute inline-flex items-center px-2.5 py-2 text-xs font-medium text-center text-gray-700 bg-white rounded-md border border-gray-300"
                  >
                    Close
                  </button>
                  <div id="galleryModel"></div>
                  <div className="flex gap-6 ">
                    <div style={{ width: 120, height: 120 }}>
                      <img
                        src={
                          companyInfo?.profile_image ||
                          "https://via.placeholder.com/120"
                        }
                        alt="company img"
                        className="w-full h-full rounded-md"
                      />
                    </div>
                    <div>
                      <div className=" font-bold text-gray-900 w-10/12">
                        {companyInfo?.name}
                      </div>
                      <div className="flex items-center gap-2 mt-2 ">
                        <div className=" w-4">
                          <img
                            src={Mobile}
                            alt="mobile Icon"
                            className="mx-auto"
                          />
                        </div>
                        <div className=" text-sm text-gray-900">
                          {companyInfo?.phone}
                        </div>
                      </div>
                      <div className="flex items-center gap-2 mt-2 ">
                        <div className=" w-4">
                          <img
                            src={Timer}
                            alt="timer Icon"
                            className="mx-auto"
                          />
                        </div>
                        <div
                          className={
                            lastModified
                              ? "text-sm text-bgred"
                              : " text-sm text-gray-900"
                          }
                        >
                          {"Last updated: "}
                          {moment(
                            companyInfo?.updatesWithlastUpdatedRecord[0]?.date
                          ).format("D MMM, YYYY")}
                        </div>
                      </div>
                      <div className="mt-3 flex items-center justify-center gap-3">
                        <button
                          onClick={() => {
                            setIsRequest(true);
                          }}
                          className=" px-2.5 py-2 text-xs font-medium text-center text-gray-700 bg-white rounded-md border border-gray-500 shadow-sm flex-shrink-0"
                        >
                          Submit a request
                        </button>
                        <div className="text-xs opacity-50">
                          {changeRequesttext
                            ? "Request sent!"
                            : "For updated material availability and price"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isRequest && (
                    <div>
                      <div className="mt-5">
                        <div className=" text-sm leading-5 font-medium text-gray-700">
                          Request details
                        </div>
                        <TextArea
                          showCount
                          maxLength={500}
                          rows={5}
                          className="rounded-md w-full pt-2"
                          onBlur={(event) => {
                            setRequestMessage(event.target.value);
                          }}
                        ></TextArea>
                        {errorMessage && (
                          <p className="text-bgred">
                            Enter the request details
                          </p>
                        )}
                        <div className="flex justify-end items-end mt-6">
                          <button
                            onClick={() => {
                              setIsRequest(false);
                              setErrorMessage(false);
                            }}
                            className=" px-5 py-2 text-sm font-medium text-center text-gray-700 bg-white rounded-md border border-gray-300"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={async () => {
                              if (requestMessage.length > 0) {
                                let resp = await submitRequest({
                                  variables: {
                                    object: {
                                      company_id: companyInfo.id,
                                      notification: requestMessage,
                                      status: "Requested",
                                    },
                                  },
                                });
                                if (resp && resp.data) {
                                  setRequestMessage("");
                                  setIsRequest(false);
                                  setChangeRequesttext(true);
                                  setErrorMessage(false);
                                  setTimeout(function () {
                                    setChangeRequesttext(false);
                                  }, 5000);
                                }
                              } else {
                                setErrorMessage(true);
                              }
                            }}
                            className=" px-5 py-2 text-sm font-medium text-center text-white bg-indigo-600 rounded-md border border-indigo-300 ml-3"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className=" flex gap-x-2.5 gap-y-3 mt-6 flex-wrap">
                    {materialsName.map((tag, tagIndex) => {
                      return (
                        <div className=" bg-green-100 rounded px-3 py-1 text-green-800 ">
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-6 h-56 rounded-lg overflow-hidden">
                    <div className="">
                      <Map
                        google={props.google}
                        style={{ height: "39vh", maxWidth: "100%" }}
                        containerStyle={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                        //bounds={new props.google.maps.LatLngBounds().extend({
                        //  lat: companyInfo?.lat,
                        //  lng: companyInfo?.long,
                        //})}
                        center={{
                          lat: companyInfo?.lat,
                          lng: companyInfo?.long,
                        }}
                      >
                        <Marker
                          key={companyInfo?.id}
                          title={companyInfo?.name}
                          name={companyInfo?.address}
                          id={companyInfo?.id}
                          position={{
                            lat: companyInfo?.lat,
                            lng: companyInfo?.long,
                          }}
                          icon={{
                            url: `data:image/svg+xml,%3Csvg width='27' height='32' viewBox='0 0 27 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27 13.5C27 20.9558 16 32 13.5 32C10.5 32 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5Z' fill='${selectedMarker}'/%3E%3Ccircle cx='13.5' cy='12.5' r='5.5' fill='white'/%3E%3C/svg%3E%0A`,
                            anchor: new props.google.maps.Point(0, 32),
                            scaledSize: new props.google.maps.Size(20, 20),
                          }}
                        />
                      </Map>
                    </div>
                  </div>
                  <div className="mt-2 text-xs text-gray3">
                    {"Address: "}
                    {companyInfo?.address}
                  </div>
                  <div className="mt-6">
                    <Tabs
                      tabitem={[
                        tabitem[0],
                        companyInfo?.images?.length > 0
                          ? tabitem[1]
                          : undefined,
                        companyInfo?.updates?.length > 0
                          ? tabitem[2]
                          : undefined,
                      ].filter((item) => item)}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                    />
                  </div>
                  {activeTab === "Insights" && companyInfo && (
                    <InsightsMaterials info={companyInfo} />
                  )}
                  {activeTab === "Gallery" &&
                    companyInfo?.images &&
                    companyInfo?.images.length > 0 &&
                    companyInfo?.images.map((image, imgIndex) => (
                      <div className="my-2">
                        <InsightGallery
                          data={image}
                          onClickImage={() => {
                            setModalData(image);
                            setIsModalVisible(true);
                          }}
                        />
                      </div>
                    ))}
                  {activeTab === "Update History" &&
                    updateHistoryList &&
                    updateHistoryList.length > 0 &&
                    updateHistoryList.map((update, updateIndex) => (
                      <div className="my-2">
                        <UpdateHistory data={update} />
                      </div>
                    ))}
                </div>
              }
              width="600px"
              placement="right"
              showMask={true}
              maskClosable={true}
            >
              {" "}
              <Modal
                visible={isModalVisible}
                centered
                footer={null}
                closable={true}
                onCancel={() => {
                  setIsModalVisible(false);
                }}
                destroyOnClose
                getContainer={document.getElementById("galleryModel")}
              >
                <div style={{ minHeight: "30vh" }}>
                  {modalData?.url && (
                    <div className="w-fit h-80 mt-8">
                      <img
                        className="rounded"
                        src={modalData?.url}
                        style={{
                          width: "98%",
                          height: "100%",
                          margin: "auto",
                          objectFit: "cover",
                        }}
                        alt="Notes Img"
                      />
                    </div>
                  )}
                  <div className="text-sm font-medium text-gray3 mt-4">
                    {moment(modalData?.created_at).format("DD MMM, YYYY")}
                    <div className="mt-2 text-sm text-gray1">
                      {modalData?.comment}
                    </div>
                  </div>
                </div>
              </Modal>
            </Drawer>
          </div>
        )}
      </div>
    </Spin>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
})(Materials);
