import { useQuery } from "@apollo/client";
import CountCard from "../../components/countCard";
import { MAPPED_USERS } from "./graphql";

export default function MappedUsers() {
  const { data } = useQuery(MAPPED_USERS);
  return (
    <>
      <div className="mt-4 text-gray-900 font-medium text-lg">
        Total Mapped Users
      </div>
      <div className="grid grid-cols-4 gap-4 mt-5">
        <CountCard
          count={data?.FlatGlass?.aggregate?.count || 0}
          stylecomponents={{ backgroundColor: "#56CCF2" }}
          description=" Flat Glass Retailers"
          className="flex-grow rounded-lg"
          widthclass="w-32"
          id={0}
          canClick={true}
        />
        <CountCard
          count={data?.LargeFlatGlass?.aggregate?.count || 0}
          stylecomponents={{ backgroundColor: "#2F80ED" }}
          description=" Large Flat Glass Aggregators (L2)"
          className="flex-grow rounded-lg"
          widthclass="w-36"
          id={1}
          canClick={true}
        />
        <CountCard
          count={data?.smallGlass?.aggregate?.count || 0}
          stylecomponents={{ backgroundColor: "#F2C94C" }}
          description=" Small Glass Bottle Aggregators (L1)"
          className="flex-grow rounded-lg"
          widthclass="w-36"
          id={2}
          canClick={true}
        />
        <CountCard
          count={data?.LargeGlass?.aggregate?.count || 0}
          stylecomponents={{ backgroundColor: "#EB5757" }}
          description=" Large Glass Bottle Aggregators (L2)"
          className="flex-grow rounded-lg"
          widthclass="w-36"
          id={3}
          canClick={true}
        />
      </div>
    </>
  );
}
