import { useLazyQuery, useQuery } from "@apollo/client";
import { notification, Spin } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import Charts from "../../components/Charts";
import Dropdown from "../../components/Dropdown";
import { GET_CLASSIFICATION_BY_ID, GET_STAKE_HOLDERS } from "./graphql";

const durationMenuItem = [
  { value: "Last 7 Days", label: "Last 7 Days" },
  { value: "Last 30 Days", label: "Last 30 Days" },
];

export default function PriceTracker() {
  const [selectedStake, setSelectedStake] = React.useState(undefined);
  const [selectedDuration, setSelectedDuration] = React.useState({
    value: "Last 30 Days",
    label: "Last 30 Days",
  });
  const [priceChartData, setPriceChartData] = React.useState([
    { label: "", data: [{ date: "", unit: 0 }] },
  ]);
  const [stakeHolders, setStakeHolders] = React.useState([]);
  const [tickValues, setTickValues] = React.useState([]);
  const generateTickValues = () => {
    let weekendObj = [];
    let weekend = moment().endOf("week").valueOf();
    weekendObj.push(weekend);
    let dateUpto = 4;
    if (selectedDuration.value === "Last 30 Days") {
      dateUpto = 4;
    } else {
      dateUpto = 7;
    }

    for (let count = 0; count < dateUpto; count++) {
      weekend = moment(weekend).subtract(dateUpto === 4 ? 7 : 1, "days");
      weekendObj.push(weekend.valueOf());
    }
    setTickValues(weekendObj.reverse());
  };
  useEffect(() => {
    generateTickValues();
  }, [selectedDuration]);
  const [setPriceChartValue, { loading: gettingClassification }] = useLazyQuery(
    GET_CLASSIFICATION_BY_ID,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.classifications && data.classifications.length > 0) {
          let priceRange = [];
          let dateRange = [];
          if (selectedDuration.value === "Last 30 Days") {
            dateRange.push(
              moment().subtract(29, "days").startOf("week").valueOf()
            );
            dateRange.push(moment().endOf("week").valueOf());
          } else {
            dateRange.push(moment().subtract(7, "days").valueOf());
            dateRange.push(moment().endOf("week").valueOf());
          }
          (data.classifications || []).map((classifi) => {
            let classification = classifi;
            let chartData = (classification.materials_x_classifications || [])
              .map((m) => {
                if (m.material.updates && m.material.updates.length) {
                  let dateWiseFilterGrp = {};
                  (m.material.updates || []).map((update) => {
                    if (!dateWiseFilterGrp[update.date]) {
                      dateWiseFilterGrp[update.date] = []
                    }
                    dateWiseFilterGrp[update.date].push(update)
                  });
                  let cmpyBasedGrp = {}, priceDataSet = [
                    {
                      date: moment(dateRange[0]).valueOf(),
                      unit: 0
                    }
                  ] //filter the cmpy based updates
                  let sortedDateGrp = {}
                  Object.keys(dateWiseFilterGrp)
                    .sort().map((data) => {
                      sortedDateGrp[data] = dateWiseFilterGrp[data]
                    }).reverse()
                  console.log(sortedDateGrp)
                  Object.keys(sortedDateGrp).map((dateKey) => {
                    let cmpyWiseFilter = {}
                    sortedDateGrp[dateKey].map((dateObj) => {
                      if (cmpyWiseFilter[dateObj.company_id]) {
                        if (
                          moment(cmpyWiseFilter[dateObj.company_id]).isAfter(
                            dateObj.updated_at
                          )
                        ) {
                          cmpyWiseFilter[dateObj.company_id] = dateObj;
                        }
                      } else {
                        cmpyWiseFilter[dateObj.company_id] = dateObj;
                      }
                    })

                    if (!cmpyBasedGrp[dateKey]) {
                      cmpyBasedGrp[dateKey] = {}
                    }
                    cmpyBasedGrp[dateKey] = (Object.values(cmpyWiseFilter))
                    console.log(cmpyWiseFilter)
                    let dateWiseprice = Object.values(cmpyWiseFilter)
                      .reduce((a, b) => {
                        return {
                          ...a,
                          quantity: a.quantity + b.quantity,
                          totalPrice: a.totalPrice ? a.totalPrice + (b.quantity * b.price) : ((a.quantity * a.price) + (b.quantity * b.price))

                          // price: a.price + b.price,
                        }
                      });
                    console.log(dateKey, dateWiseprice)
                    priceDataSet.push({
                      date: moment(dateKey).valueOf(),
                      unit: parseFloat((((dateWiseprice.totalPrice ? dateWiseprice.totalPrice / dateWiseprice.quantity : dateWiseprice.price)) * 1000).toFixed(2))
                    })
                  })
                  console.log(priceDataSet)
                  return ({
                    label: m.material.name,
                    data: priceDataSet.sort((a, b) => a.date > b.date),
                  });
                } else {
                  return undefined;
                }


              })
              .filter((cData) => cData);
            if (chartData.length) {
              chartData.map((cData) => {
                // let nonZeroData = cData.data.filter((point) => point.unit > 0);
                priceRange.push({
                  label: cData.label,
                  data: cData.data,
                });
              });
            }
          });
          if (priceRange.length > 0) setPriceChartData(priceRange);
          else
            setPriceChartData([{ label: "", data: [{ date: "", unit: 0 }] }]);
        }
      },
      onError: (error) => {
        notification.error({ message: error.message });
      },
    }
  );
  const { loading: gettingStakeholders } = useQuery(GET_STAKE_HOLDERS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      let durationObj = {};
      if (selectedDuration) {
        if (selectedDuration.value === "Last 7 Days") {
          let startDate = moment().subtract(6, "days").startOf("day");
          let endDate = moment().endOf("day");
          durationObj = { date: { _gt: startDate, _lte: endDate } };
        } else if (selectedDuration.value === "Last 30 Days") {
          let startDate = moment().subtract(29, "days").startOf("day");
          let endDate = moment().endOf("day");
          durationObj = { date: { _gt: startDate, _lte: endDate } };
        }
      } else {
        let startDate = moment()
          .endOf("week")
          .subtract(12, "weeks")
          .startOf("day");
        let endDate = moment().endOf("week");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      }
      if (data.classifications) {
        let stakeHold = data.classifications.map((classification) => {
          return {
            value: classification.id,
            label: classification.name,
          };
        });
        setStakeHolders(stakeHold);
        setSelectedStake(stakeHold[0] || undefined);

        setPriceChartValue({
          variables: {
            id: [stakeHold?.[0]?.value || undefined],
            dateCond: durationObj,
          },
        });
      }
    },
  });

  useEffect(() => {
    let durationObj = {};
    if (selectedDuration) {
      if (selectedDuration.value === "Last 7 Days") {
        let startDate = moment().subtract(6, "days").startOf("day");
        let endDate = moment().endOf("day");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      } else if (selectedDuration.value === "Last 30 Days") {
        let startDate = moment().subtract(29, "days").startOf("day");
        let endDate = moment().endOf("day");
        durationObj = { date: { _gt: startDate, _lte: endDate } };
      }
    } else {
      let startDate = moment()
        .endOf("week")
        .subtract(12, "weeks")
        .startOf("day");
      let endDate = moment().endOf("week");
      durationObj = { date: { _gt: startDate, _lte: endDate } };
    }
    let stakeHoldersArr = [];
    if (selectedStake?.value) {
      setPriceChartValue({
        variables: {
          id: [selectedStake?.value],
          dateCond: durationObj,
        },
      });
    } else if (stakeHolders.length) {
      stakeHolders.map((stakeHolder) => {
        stakeHoldersArr.push(stakeHolder.value);
      });
      setPriceChartValue({
        variables: {
          id: stakeHoldersArr,
          dateCond: durationObj,
        },
      });
    } else if (!selectedStake) {
      setPriceChartData([{ label: "", data: [{ date: "", unit: 0 }] }]);
    }
  }, [stakeHolders, selectedStake, selectedDuration]);

  // useEffect(() => {
  //   setSelectedDuration(durationMenuItem[1]);
  // }, []);

  return (
    <Spin spinning={gettingClassification || gettingStakeholders}>
      <div className="font-sans">
        <div className="flex justify-between">
          <div className=" flex gap-5">
            <div className="text-black text-2xl font-bold">Price Tracker</div>
            <div className="flex gap-2 ">
              <Dropdown
                placeholder="Select Stakeholder"
                menuitem={stakeHolders}
                sizeClassName=" w-52 "
                handleChange={(classification) => {
                  setSelectedStake(classification || undefined);
                }}
                selectedOption={selectedStake}
              />
            </div>
          </div>
          <div>
            <Dropdown
              placeholder="Duration"
              menuitem={durationMenuItem}
              sizeClassName="w-44"
              handleChange={(duration) => {
                setSelectedDuration(duration || undefined);
              }}
              selectedOption={selectedDuration}
            />
          </div>
        </div>
        <div className=" mt-10 px-10 py-16 bg-white rounded-lg h-80vh">
          <Charts chartData={priceChartData} tickValues={tickValues} />
        </div>
      </div>
    </Spin>
  );
}
