import { gql } from "@apollo/client";

export const MATERIALS_LIST = gql`
  query MATERIALS_LIST(
    $where: materials_bool_exp
    $locationWhere: updates_bool_exp
    $limit: Int
    $offset: Int
  ) {
    materialsTableData: materials(
      where: $where
      limit: $limit
      offset: $offset
    ) {
      id
      name
      updates(
        order_by: { date: desc, updated_at: desc }
        where: $locationWhere
      ) {
        price
        quantity
        type_quantity
        date
        updated_at
        company {
          id
          name
          lat
          long
          location
        }
      }
      materials_x_classifications(order_by: { updated_at: desc }, limit: 1) {
        id
        classification {
          id
          name
          colour
        }
      }
    }
    materialsSelectData: materials {
      id
      name
      updates(order_by: { updated_at: desc }, limit: 1) {
        price
        quantity
        type_quantity
        company {
          id
          location
        }
      }
    }
    classifications {
      id
      name
    }
  }
`;

export const COMPANIES = gql`
  query companies($id: Int!, $where: updates_bool_exp) {
    companies(
      where: { id: { _eq: $id } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      updates(order_by: { date: desc, updated_at: desc }, where: $where) {
        id
        materials_id
        price
        quantity
        date
        type_quantity
        updated_at
        material {
          id
          name
          materials_x_classifications {
            classification {
              id
              name
            }
          }
        }
      }
      dataHistory: updates(
        where: { company_id: { _eq: $id } }
        distinct_on: file_id
        order_by: { file_id: desc }
      ) {
        file_id
        file {
          type
          created_at
        }
      }
      imageHistory: images(
        where: { company_id: { _eq: $id } }
        distinct_on: file_id
        order_by: { file_id: desc }
      ) {
        file_id
        file {
          type
          created_at
        }
      }
      updatesWithlastUpdatedRecord: updates(
        order_by: { date: desc }
        limit: 1
      ) {
        id
        date
        updated_at
      }
      id
      name
      address
      phone
      lat
      long
      profile_image
      images(order_by: { created_at: desc }) {
        id
        url
        comment
        created_at
      }
    }
  }
`;

export const SUBMIT_REQUEST = gql`
  mutation insert_notifications_one($object: notifications_insert_input!) {
    insert_notifications_one(object: $object) {
      id
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies {
    companies {
      id
      name
      location
      address
      lat
      long
    }
  }
`;

// export const UPDATE_HISTORY = gql`
//   query UPDATE_HISTORY($id: Int!) {
//     updates(where: { company_id: { _eq: $id } }, distinct_on: file_id) {
//       file_id
//       file {
//         type
//         created_at
//       }
//     }
//   }
// `;

export const Classification = gql`
  query Classification {
    classifications {
      name
      id
    }
  }
`;
