import { Modal } from "antd";
import "antd/dist/antd.css";

const ConfirmationModal = (props) => {
  return (
    <>
      <Modal
        title={
          <span>
            Log out <span className="text-xs">(version 1.0.6)</span>
          </span>
        }
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        okText="Log Out"
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
