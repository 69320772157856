import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import ErrorBoundary from "../components/ErrorBoundary";
import Login from "../pages/login/login";
import Dashboard from "../pages";

export default function Routes() {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/admin" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} roles={["sg"]} />
        <PrivateRoute path="/materials" component={Dashboard} roles={["sg"]} />
        <PrivateRoute
          path="/volume-tracker"
          component={Dashboard}
          roles={["sg"]}
        />
        <PrivateRoute
          path="/price-tracker"
          component={Dashboard}
          roles={["sg"]}
        />
        <PrivateRoute path="/metadata" component={Dashboard} roles={["sg"]} />
        <PrivateRoute path="/uploadData" component={Dashboard} roles={["kc"]} />
        <PrivateRoute
          path="/manageRequests"
          component={Dashboard}
          roles={["kc"]}
        />
        <Route component={ErrorBoundary} />
      </Switch>
    </main>
  );
}
