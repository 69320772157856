import { useQuery, useSubscription } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import bell from "../../assets/bell.svg";
import MappedUsers from "./mappedUsers";
import Stakeholders from "./stakeholdersDetails";
import Drawer from "../../components/Drawer";
import { NOTIFICATION_SUBSCRIPTION, GET_NOTIFICATION } from "./graphql";
import moment from "moment";

export default function Dashboard() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [list, setList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const { data: count } = useSubscription(NOTIFICATION_SUBSCRIPTION);
  const { data: notificationData } = useQuery(GET_NOTIFICATION);

  useEffect(() => {
    if (count) {
      setNotificationCount(count?.notifications_aggregate?.aggregate?.count);
    }
  }, [count]);

  useEffect(() => {
    if (notificationData) {
      let object = JSON.parse(JSON.stringify(notificationData.notifications));

      object.forEach((item) => {
        item.companyName = item?.company?.name;
        item.requestedDate = `Requested on: ${moment(item?.created_at).format(
          "DD MMM, YYYY"
        )}`;
      });
      setList(object);
    }
  }, [notificationData]);

  return (
    <>
      <div>
        <p className="float-left text-black text-2xl font-bold">Dashboard</p>
        <button
          type="button"
          className="float-right inline-flex items-center px-4 py-2 text-xs font-medium text-center text-gray-700 bg-white rounded-md border border-gray-300"
          onClick={() => setOpenDrawer(true)}
        >
          Historical Activity Tracker
          {notificationCount === 0 ? (
            <img
              style={{ width: "20px", height: "15px" }}
              className="ml-2"
              alt="notification"
              src={bell}
            />
          ) : (
            <span className="inline-flex items-center justify-center p-1.5 h-4 ml-2 text-xs font-semibold text-white bg-red-500 rounded-lg">
              {notificationCount}
            </span>
          )}
        </button>
        <div className="clear-both"></div>
      </div>
      <MappedUsers />
      <Stakeholders />
      <Drawer
        prefixCls="drawer"
        open={openDrawer}
        toggleHandler={() => {
          setOpenDrawer(false);
        }}
        closeButton={
          <div className="py-6 px-8 font-sans relative">
            <div className="mb-9">
              <button
                onClick={() => {
                  setOpenDrawer(false);
                }}
                className="right-6 top-6 absolute inline-flex items-center px-2.5 py-2 text-xs font-medium text-center text-gray-700 bg-white rounded-md border border-gray-300"
              >
                Close
              </button>
              <div>
                <span className="text-gray-900 font-semibold text-base w-full">
                  Historical Activity Tracker
                </span>
                <span className="text-black opacity-50 font-normal text-xs block">
                  Requests for updated material availability &amp; price
                </span>
              </div>
            </div>
            <Fragment>
              {list.map((item, index) => {
                return (
                  <div className="mb-6" key={index}>
                    <div className="float-left w-72">
                      <span className="text-gray-900 font-semibold text-sm w-full">
                        {item.companyName}
                      </span>
                      <span className="text-gray-900 font-normal text-xs block mt-1">
                        {item.requestedDate}
                      </span>
                    </div>
                    {item.status === "Complete" ? (
                      <div className="w-24 float-right bg-green-100 text-sm rounded-xl text-center text-green-800 ">
                        Complete
                      </div>
                    ) : (
                      <div className="w-24 float-right bg-bgYellow text-sm rounded-xl text-center text-textYellow ">
                        {item.status}
                      </div>
                    )}
                    <div className="clear-both"></div>
                    {item.notification !== null && item.notification !== "" && (
                      <div className="text-gray-900 font-normal text-sm mt-1 block">
                        {item.notification}
                      </div>
                    )}
                    {item.comment !== null && item.comment !== "" && (
                      <>
                        <div className="text-black opacity-60 font-normal mt-2 text-xs block">
                          Kabadiwalla Connect
                        </div>
                        <div className="text-gray-900 font-normal text-sm mt-1 block">
                          {item.comment}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </Fragment>
          </div>
        }
        width="460px"
        placement="right"
        showMask={true}
        maskClosable={true}
      ></Drawer>
    </>
  );
}
