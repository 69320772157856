export async function getS3UploadUrl(fileName, contentType) {
  const query = {
    query: `mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    file_upload_url(content_type: $contentType, file_name: $fileName) {
      message
      url
    }
  }`,
    variables: {
      fileName: fileName,
      contentType: contentType,
    },
  };
  let postData = await fetch(`${process.env.REACT_APP_API}`, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const { data: uploadLink } = await postData.json();
  return [uploadLink.file_upload_url.url, { statuscode: postData.status }];
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function (e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          xhr.open("put", urlList[index], true);
          if (file.type === "text/csv") {
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("x-amz-acl", "public-read");
          }
          xhr.send(file);
        })
    );

    Promise.all(formUploadPromiseArray)
      .then((urls) => {
        resolve(urls.map((url) => url.split("?")[0]));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};

export function numFormatter(num) {
  if (num > 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(2) + "M";
  } else if (num >= 1000000000 && num < 1000000000000) {
    return (num / 1000000000).toFixed(2) + "B";
  } else if (num >= 1000000000000) {
    return (num / 1000000000000).toFixed(2) + "T";
  } else {
    return num;
  }
}
