import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const CountCard = (props) => {
  const history = useHistory();
  return (
    <div
      className={`p-5 flex flex-col bg-white ${props.className} ${
        props.canClick ? "cursor-pointer hover:shadow-lg" : ""
      }`}
      onClick={() =>
        props.canClick
          ? history.push(`/materials?stakeid=${props.id}`)
          : undefined
      }
    >
      <div className={props.widthclass}>
        <span className="text-base text-gray-500">{props.description}</span>
        {props.stylecomponents !== null && (
          <span
            style={props.stylecomponents}
            className="w-2 h-2 inline-block rounded-full ml-2"
          ></span>
        )}
      </div>
      <div className="text-3xl text-gray-900 font-semibold mt-1">
        {props.count}
      </div>
    </div>
  );
};

CountCard.defaultProps = {
  count: 0,
  description: "",
  className: "",
};

CountCard.propTypes = {
  count: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  widthclass: PropTypes.string,
  stylecomponents: PropTypes.object,
};

export default CountCard;
