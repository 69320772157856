export default function CustomTable(props) {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        className="w-full text-sm text-left text-gray-500 customTable"
        id={props.id}
      >
        <thead className="uppercase bg-gray-50">
          <tr>
            {props.columns.map((item) => {
              return (
                <th
                  scope="col"
                  className={` ${
                    props.small ? "px-3 py-3" : "px-6 py-3"
                  }  text-xs text-gray-500 font-medium`}
                  key={item.key}
                >
                  {item.name}
                </th>
              );
            })}
          </tr>
        </thead>
        {props?.data?.length === 0 && (
          <div className=" p-6 text-gray-500 font-medium text-base ">
            No data found
          </div>
        )}
        <tbody>
          {props.data.map((row, index) => {
            return (
              <tr className="bg-white" key={index}>
                {props.columns?.map((col, key) => {
                  return (
                    <td
                      key={row[col.key]}
                      className={` ${props.small ? "px-3 py-3" : "px-6 py-3"}
                      ${
                        key === props.boldColumnIndex
                          ? "font-medium text-gray-900 text-sm"
                          : ""
                      }`}
                    >
                      {col.render
                        ? col.render(row, col.key, props.data)
                        : row[col.key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
