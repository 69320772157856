import { gql } from "@apollo/client";

export const GET_STAKE_HOLDERS = gql`
  query classifications {
    classifications {
      id
      name
    }
  }
`;

export const GET_CLASSIFICATION_BY_ID = gql`
  query classificationById($id: [Int!], $dateCond: updates_bool_exp) {
    classifications(where: { id: { _in: $id } }) {
      id
      name
      materials_x_classifications {
        id
        material_id
        updated_at
        material {
          id
          name
          updates(order_by: { updated_at: desc }, where: $dateCond) {
            id
            date
            quantity
            price
            updated_at
            company_id
          }
        }
      }
    }
  }
`;
