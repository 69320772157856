import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import CountCard from "../../components/countCard";
import Dropdown from "../../components/Dropdown";
import {
  REQUEST_DETAILS,
  REQUEST_LIST,
  UPDATE_NOTIFICATIONS_STATUS,
  UPDATE_COMMENTS,
} from "./graphql";
import moment from "moment";

export default function ManageRequests() {
  const [selectedOption, setSelectedOption] = useState({
    value: "Show all",
    label: "Show all",
  });
  const menuitem = [
    { value: "Show_all", label: "Show all" },
    { value: "Requested", label: "Requested" },
    { value: "In_process", label: "In process" },
    { value: "Completed", label: "Completed" },
  ];

  const statusValue = {
    Show_all: ["Requested", "In process", "Complete"],
    Requested: ["Requested"],
    In_process: ["In process"],
    Completed: ["Complete"],
  };

  const statusmenu = [
    { value: "In process", label: "In Process" },
    { value: "Complete", label: "Complete" },
  ];

  const [selectedOptionStatus, setSelectedOptionStatus] = useState([]);
  const [openComment, setOpenComment] = useState([]);
  const [requestDetails, setRequestDetails] = useState([]);
  const [comment, setComments] = useState([]);
  const [updateNotificationsStatus] = useMutation(UPDATE_NOTIFICATIONS_STATUS);
  const [updateComments] = useMutation(UPDATE_COMMENTS);
  const { data: requestData, refetch } = useQuery(REQUEST_DETAILS);
  const [getSelectedStatusDetails, { data: requestList }] =
    useLazyQuery(REQUEST_LIST);

  const getDetails = (status) => {
    getSelectedStatusDetails({
      variables: {
        status: status,
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    getDetails(statusValue.Show_all);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newCopy = [...selectedOptionStatus];
    let openCommentArray = [...openComment];
    let newCommentArray = [...comment];
    if (requestList) {
      let object = JSON.parse(JSON.stringify(requestList.notifications));

      object.forEach((item, index) => {
        item.companyName = item?.company?.name;
        item.requestedDate = `Requested on: ${moment(item?.created_at).format(
          "DD MMM, YYYY"
        )}`;
        newCopy[index] = { value: item.status, label: item.status };
        openCommentArray[index] = false;
        newCommentArray[index] = item.comment;
      });
      setRequestDetails(object);
      setOpenComment(openCommentArray);
      setComments(newCommentArray);
      setSelectedOptionStatus(newCopy);
    }
    // eslint-disable-next-line
  }, [requestList]);

  const handleChange = (selectedOption) => {
    const val = selectedOption.value;
    setSelectedOption(selectedOption);
    getDetails(statusValue[val]);
  };

  const handleStatusChange = (statusOption, index) => {
    let newArray = [...selectedOptionStatus];
    newArray[index] = statusOption;
    updateNotificationsStatus({
      variables: {
        id: requestList.notifications[index].id,
        status: statusOption.value,
      },
    }).then(() => {
      setSelectedOptionStatus(newArray);
      refetch();
    });
  };

  const handleSubmit = (index) => {
    updateComments({
      variables: {
        id: requestList.notifications[index].id,
        comment: comment[index],
      },
    }).then(() => {
      let openCommentArray = [...openComment];
      openCommentArray[index] = false;
      setOpenComment(openCommentArray);
    });
  };

  const handleText = (value, index) => {
    const newArray = [...comment];
    newArray[index] = value;
    setComments(newArray);
  };

  return (
    <div>
      <p className=" text-black text-2xl font-bold w-full">Manage Requests</p>
      <div className="grid grid-cols-3 gap-4 mt-5 block">
        <CountCard
          count={requestData?.request?.aggregate?.count}
          description="Requests"
          className="flex-grow rounded-lg"
          widthclass="w-32"
        />
        <CountCard
          count={requestData?.in_process?.aggregate?.count}
          description="In process"
          className="flex-grow rounded-lg"
          widthclass="w-36"
        />
        <CountCard
          count={requestData?.complete?.aggregate?.count}
          description="Completed"
          className="flex-grow rounded-lg"
          widthclass="w-36"
        />
      </div>
      <div className="mt-9 rounded-md bg-white py-5 px-48">
        <Dropdown
          placeholder="Show all"
          menuitem={menuitem}
          handleChange={handleChange}
          selectedOption={selectedOption}
        />
        {requestDetails.length !== 0 ? (
          requestDetails.map((item, index) => {
            return (
              <div key={index}>
                <div className="mt-9">
                  <div className="float-left w-72">
                    <span className="text-gray-900 font-semibold text-sm w-full">
                      {item.companyName}
                    </span>
                    <span className="text-gray-900 font-normal text-xs block mt-1">
                      {item.requestedDate}
                    </span>
                  </div>
                  <div className="float-right items-center px-4 py-2">
                    <Dropdown
                      placeholder="Status"
                      menuitem={statusmenu}
                      index={index}
                      handleChange={handleStatusChange}
                      selectedOption={selectedOptionStatus[index]}
                    />
                  </div>
                  <div className="clear-both"></div>
                </div>

                <div className="py-4">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                {item.notification !== null && item.notification !== "" && (
                  <div className="text-sm text-gray-900 font-normal">
                    {item.notification}
                  </div>
                )}
                {selectedOptionStatus[index].value !== "Requested" && (
                  <button
                    className="text-sm font-normal text-violet border-0"
                    onClick={() => {
                      let newArray = [...openComment];
                      newArray[index] = true;
                      setOpenComment(newArray);
                    }}
                  >
                    Add comment
                  </button>
                )}
                {openComment[index] && (
                  <div>
                    <p className="text-gray-700 font-medium text-sm">
                      Comment details
                    </p>
                    <textarea
                      className="w-full border-gray-300 border rounded-md mt-1 p-2"
                      id="comments"
                      name="comments"
                      rows="4"
                      cols="50"
                      value={comment[index]}
                      onChange={(e) => handleText(e.target.value, index)}
                    ></textarea>
                    <div className="text-right">
                      <button
                        className="px-6 py-2 mt-4 text-gray-700 bg-white rounded-lg border border-gray-300"
                        onClick={() => {
                          let newArray = [...openComment];
                          newArray[index] = false;
                          setOpenComment(newArray);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="ml-3 px-6 py-2 mt-4 text-white bg-indigo-600 rounded-lg "
                        onClick={() => handleSubmit(index)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div>
            <div className="flex flex-col items-center justify-center">
              <div className="p-6 w-2/3 bg-white  rounded flex flex-col items-center justify-center">
                <div className="text-h6 text-textLight mb-4">No Data Found</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
