import React from "react";
import Select, { components } from "react-select";
import dropdown from "../assets/dropdown.svg";

export default function Dropdown(props) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused
        ? "rgb(229 231 235)"
        : state.isSelected
        ? "rgb(156 163 175)"
        : undefined,
      color: "rgb(75 85 99)",
      zIndex: 1,
      bottom: "50%",
    }),
    control: (styles, state) => ({
      ...styles,
      border: "1px solid rgba(209, 213, 219, 1)",
      boxShadow: "none",
      borderRadius: "6px",
      "&:hover": {
        border: "1px solid rgba(209, 213, 219, 1)",
      },
    }),
    singleValue: (provided, state) => {
      return { ...provided };
    },
    placeholder: (styles, state) => ({
      ...styles,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#374151",
    }),
  };

  const onChange = (value) => {
    props.handleChange(value, props.index);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdown} alt="dropdown" className="mr-1" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={`${
        props.sizeClassName ? props.sizeClassName : "w-44"
      } text-sm text-gray-700 font-normal`}
    >
      <Select
        isClearable={props.allowClear || false}
        styles={customStyles}
        placeholder={props.placeholder}
        components={{ DropdownIndicator }}
        options={props.menuitem}
        onChange={onChange}
        value={props.selectedOption}
        isMulti={props.multi || false}
      />
    </div>
  );
}
