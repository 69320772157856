import CountCard from "../../components/countCard";
import CustomTable from "../../components/CustomTable";

export default function LargeFlatGlass(props) {
  const columns = [
    {
      name: "Material Name",
      key: "materialName",
      className: "font-medium text-gray-900 text-sm",
    },
    {
      name: "Volume",
      key: "volume",
    },
    {
      name: "Avg Price",
      key: "avgPrice",
    },
    {
      name: "Volume Procured",
      key: "volumeProcured",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mt-5">
        <CountCard
          count={props.material || ""}
          stylecomponents={null}
          description="Material available"
          className="flex-grow rounded-lg"
          widthclass="w-full"
        />
        <CountCard
          count={props.price || ""}
          stylecomponents={null}
          description="Average price (per ton)"
          className="flex-grow rounded-lg"
          widthclass="w-full"
        />
        <CountCard
          count="-"
          stylecomponents={null}
          description="Total volume procured"
          className="flex-grow rounded-lg"
          widthclass="w-full"
        />
      </div>
      <div className="mt-6">
        <CustomTable
          columns={columns}
          data={props.tableData}
          boldColumnIndex={0}
          id="LargeFlatGlass"
        />
      </div>
    </>
  );
}
