import { useState } from "react";
import FileUpload from "./FileUpload";

export default function UploadData() {
  const [displayStatus, setDisplayStatus] = useState("");
  const [imageStatus, setImageStatus] = useState("");

  const fileStatus = (value) => {
    setDisplayStatus(value);
  };

  const imageFileStatus = (value) => {
    setImageStatus(value);
  };

  return (
    <>
      <div>
        <p className=" text-black text-2xl font-bold">Upload Data</p>
        <p className="text-gray-700 mt-5 text-sm font-medium">
          Upload CSV file
        </p>
        <FileUpload fileStatus={fileStatus} type="data" />
        {displayStatus === "parse" && (
          <div className="p-6 mt-7 bg-white w-full h-24 rounded-lg">
            <p className="text-gray-900 font-medium text-lg mb-0">
              Uploading csv file
            </p>
            <p className="text-gray-500 font-normal text-sm">
              This might take a few seconds
            </p>
          </div>
        )}
        {displayStatus === "success" && (
          <div className="p-6 mt-7 bg-white w-full h-24 rounded-lg">
            <p className="text-gray-900 font-medium text-lg mb-0">
              Upload Successful
            </p>
            <p className="text-gray-500 font-normal text-sm">
              Records have been updated
            </p>
          </div>
        )}
        {displayStatus === "error" && (
          <div className="p-6 mt-7 bg-white w-full h-28 rounded-lg">
            <p className="text-bgred font-medium text-lg mb-0">
              Oops! Upload failed
            </p>
            <p className="text-gray-500 font-normal text-sm mb-0">
              Error details
            </p>
            <p className="text-lightgray font-normal text-xs ">
              Please upload CSV in the right format
            </p>
          </div>
        )}
      </div>
      <div className="mt-10">
        <p className="text-gray-700 mt-5 text-sm font-medium">
          Upload Company & Gallery Images
        </p>
        <FileUpload imageFileStatus={imageFileStatus} type="images" />
        {imageStatus === "parse" && (
          <div className="p-6 mt-7 bg-white w-full h-24 rounded-lg">
            <p className="text-gray-900 font-medium text-lg mb-0">
              Uploading csv file
            </p>
            <p className="text-gray-500 font-normal text-sm">
              This might take a few seconds
            </p>
          </div>
        )}
        {imageStatus === "success" && (
          <div className="p-6 mt-7 bg-white w-full h-24 rounded-lg">
            <p className="text-gray-900 font-medium text-lg mb-0">
              Upload Successful
            </p>
            <p className="text-gray-500 font-normal text-sm">
              Records have been updated
            </p>
          </div>
        )}
        {imageStatus === "error" && (
          <div className="p-6 mt-9 bg-white w-full h-28 rounded-lg">
            <p className="text-bgred font-medium text-lg mb-0">
              Oops! Upload failed
            </p>
            <p className="text-gray-500 font-normal text-sm mb-0">
              Error details
            </p>
            <p className="text-lightgray font-normal text-xs ">
              Please upload CSV in the right format
            </p>
          </div>
        )}
      </div>
    </>
  );
}
