import dashboard from "../assets/dashboard.svg";
import logo from "../assets/logo.svg";
import kcLogo from "../assets/kclogo.svg";
import logOut from "../assets/logOut.svg";
import materials from "../assets/materials.svg";
// import metadata from "../assets/metadata.svg";
import price from "../assets/price-tracker.svg";
import volume from "../assets/volume-tracker.svg";
import uploadData from "../assets/uploadData.svg";
import manageRequests from "../assets/manageRequest.svg";

export default function SideBar(props) {
  return (
    <div className="flex w-64 h-screen px-4 py-5 border-r bg-white flex-shrink-0 ">
      <aside className="w-full" aria-label="Sidebar">
        {props.role === "kc" ? (
          <>
            <img
              alt="kcLogo"
              src={kcLogo}
              style={{ width: "146px", height: "64px" }}
            />
            <ul className=" mt-8">
              <li
                className={`${
                  props.tabName === "uploadData"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("uploadData")}
                >
                  <img
                    alt="uploadData"
                    src={uploadData}
                    className={`${
                      props.tabName === "uploadData" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Upload Data</span>
                </button>
              </li>
              <li
                className={`${
                  props.tabName === "manageRequests"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("manageRequests")}
                >
                  <img
                    src={manageRequests}
                    alt="manageRequests"
                    className={`${
                      props.tabName === "manageRequests" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Manage Requests</span>
                </button>
              </li>
              <li className="bottom-5 absolute">
                <button
                  className=" flex items-center p-2 pb-0 text-sm font-medium w-full text-gray-600 hover:text-gray-900 rounded-lg "
                  onClick={() => {
                    props.handleLogout();
                  }}
                >
                  <img src={logOut} alt="logout" />
                  <span className="ml-3">Log out</span>
                </button>
              </li>
            </ul>
          </>
        ) : (
          <>
            <img
              alt="logo"
              src={logo}
              style={{ width: "146px", height: "64px" }}
            />
            <ul className=" mt-8">
              <li
                className={`${
                  props.tabName === "dashboard"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("dashboard")}
                >
                  <img
                    alt="dashboard"
                    src={dashboard}
                    className={`${
                      props.tabName === "dashboard" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Dashboard</span>
                </button>
              </li>
              <li
                className={`${
                  props.tabName === "materials"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("materials")}
                >
                  <img
                    src={materials}
                    alt="materials"
                    className={`${
                      props.tabName === "materials" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Materials</span>
                </button>
              </li>
              <li
                className={`${
                  props.tabName === "volume-tracker"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("volume-tracker")}
                >
                  <img
                    src={volume}
                    alt="volume"
                    className={`${
                      props.tabName === "volume-tracker" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Volume Tracker</span>
                </button>
              </li>
              <li
                className={`${
                  props.tabName === "price-tracker"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("price-tracker")}
                >
                  <img
                    alt="price"
                    src={price}
                    className={`${
                      props.tabName === "price-tracker" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Price Tracker</span>
                </button>
              </li>
              {/* <li
                className={`${
                  props.tabName === "metadata"
                    ? "bg-gray-100 rounded-md text-black"
                    : "text-gray-600 hover:text-gray-700"
                } mt-2`}
              >
                <button
                  className="flex items-center p-2 text-sm font-medium w-full rounded-lg hover:bg-gray-100"
                  onClick={() => props.onTabChange("metadata")}
                  disabled
                >
                  <img
                    src={metadata}
                    alt="metadata"
                    className={`${
                      props.tabName === "metadata" ? "" : "opacity-50"
                    }`}
                  />
                  <span className="ml-3">Meta Data</span>
                </button>
              </li> */}
              <li className=" bottom-5 absolute">
                <button
                  className=" flex items-center p-2 pb-0 text-sm font-medium w-full text-gray-600 hover:text-gray-700 rounded-lg "
                  onClick={() => {
                    props.handleLogout();
                  }}
                >
                  <img src={logOut} alt="logout" />
                  <span className="ml-3">Log out</span>
                </button>
              </li>
            </ul>
          </>
        )}
      </aside>
    </div>
  );
}
