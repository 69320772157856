import { gql } from "@apollo/client";

export const INSERT_FILES = gql`
  mutation INSERT_FILES($fileUrl: String!, $type: String) {
    insert_files(objects: { file_url: $fileUrl, type: $type }) {
      affected_rows
      returning {
        type
      }
    }
  }
`;
