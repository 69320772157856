import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideBar from "../components/SideBar";
import Dashboard from "./dashboard";
import Materials from "./materials";
import PriceTracker from "./priceTracker";
import VolumeTracker from "./volumeTracker";
import UploadData from "./uploadData";
import ManageRequests from "./manageRequests";
import ConfirmationModal from "../components/confirmationModal";
import kcLogo from "../assets/kclogo.svg";
import jwtDecode from "jwt-decode";

export default function DashboardPage() {
  const [tabName, setTabName] = useState("dashboard");
  const [openModal, setOpenModal] = useState(false);
  const [role, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ]
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (role === "kc") {
      setRole("kc");
      document.querySelector("title").textContent = "Kabadiwalla Connect";
      document.querySelector("link[rel~='icon']").href = kcLogo;
    } else {
      setRole("sg");
    }
    const value = location.pathname.split("/")[1];
    setTabName(value);
  }, [location, role]);

  const onTabChange = (value) => {
    setTabName(value);
    history.replace(`/${value}`);
  };
  const handleLogout = () => {
    setOpenModal(true);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleOk = () => {
    localStorage.clear();
    setOpenModal(false);
    if (role === "kc") {
      history.push("/admin");
    } else {
      history.push("/");
    }
  };
  return (
    <div className="flex bg-gray-100">
      <SideBar
        onTabChange={onTabChange}
        tabName={tabName}
        role={role}
        handleLogout={handleLogout}
      />
      {role === "kc" ? (
        <>
          {" "}
          <div className="w-full h-full min-h-screen p-7 overflow-y-auto max-h-screen">
            {tabName === "uploadData" && <UploadData />}
            {tabName === "manageRequests" && <ManageRequests />}
          </div>
          {openModal && (
            <ConfirmationModal
              handleCancel={handleCancel}
              handleOk={handleOk}
              isModalVisible={openModal}
            />
          )}
        </>
      ) : (
        <div className="w-full h-full min-h-screen p-7 overflow-y-auto max-h-screen ">
          {tabName === "dashboard" && <Dashboard />}
          {tabName === "materials" && <Materials />}
          {tabName === "volume-tracker" && <VolumeTracker />}
          {tabName === "price-tracker" && <PriceTracker />}
          {/* {tabName === "metadata" && (
            <div className="flex items-center justify-center p-40 ">
              metadata
            </div>
          )} */}
          {openModal && (
            <ConfirmationModal
              handleCancel={handleCancel}
              handleOk={handleOk}
              isModalVisible={openModal}
            />
          )}
        </div>
      )}
    </div>
  );
}
