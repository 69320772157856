import React from "react";
import { useHistory } from "react-router-dom";

export default function ErrorBoundary() {
  const history = useHistory();
  return (
    <div>
      <div className="pt-32 flex flex-col items-center justify-center">
        <div className="p-6 w-2/3 bg-white shadow rounded flex flex-col items-center justify-center">
          <div className="text-h6 text-textLight mb-4">
            Looks like something went wrong / Enter a valid URL. Please try
            again
          </div>
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded"
            onClick={() => history.goBack()}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}
