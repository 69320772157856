import moment from "moment";
import React from "react";

export default function InsightGallery({ data = {}, ...props }) {
  return (
    <div>
      <div className="flex gap-5">
        <img
          src={data?.url || "https://via.placeholder.com/120"}
          alt="company img"
          className=" rounded-md"
          height={120}
          width={120}
          style={{ minHeight: 120 }}
          onClick = {() => {
            if(props.onClickImage()) 
             props.onClickImage();
          }}
        />
        <div className=" text-sm font-medium text-gray3">
          {moment(data.created_at).format("DD MMM, YYYY")}
          <div className="mt-2 text-sm text-gray1">{data?.comment || ""}</div>
        </div>
      </div>
    </div>
  );
}
