import { gql } from "@apollo/client";

export const REQUEST_DETAILS = gql`
  query REQUEST_DEATAILS {
    request: notifications_aggregate(where: { status: { _eq: "Requested" } }) {
      aggregate {
        count
      }
    }
    in_process: notifications_aggregate(
      where: { status: { _eq: "In process" } }
    ) {
      aggregate {
        count
      }
    }
    complete: notifications_aggregate(where: { status: { _eq: "Complete" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const REQUEST_LIST = gql`
  query REQUEST_LIST($status: [String!]) {
    notifications(
      where: { status: { _in: $status } }
      order_by: { created_at: desc }
    ) {
      company {
        name
        created_at
      }
      created_at
      notification
      status
      comment
      id
    }
  }
`;

export const UPDATE_NOTIFICATIONS_STATUS = gql`
  mutation UPDATE_NOTIFICATIONS_STATUS($id: Int!, $status: String) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_COMMENTS = gql`
  mutation UPDATE_COMMENTS($id: Int!, $comment: String) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: { comment: $comment }
    ) {
      affected_rows
    }
  }
`;
