import moment from "moment";
import React, { useEffect, useState } from "react";
import ArrowImage from "../../assets/icons/chevron-down.svg";
import Charts from "../../components/Charts";
import CustomTable from "../../components/CustomTable";
import { numFormatter } from "../../utils/index";

const Accordion = ({ title, isActive, desc, onAccordionClick }) => {
  return (
    <div>
      <div
        className=" flex items-center justify-between cursor-pointer "
        onClick={() => {
          onAccordionClick();
        }}
      >
        <div className=" py-4 w-10/12 font-bold text-gray-900  ">{title}</div>
        <div
          className={`transition-all duration-500 transform ${isActive ? "rotate-180" : ""
            } `}
        >
          <img src={ArrowImage} alt="Down Arrow" />
        </div>
      </div>
      <div
        class={`panel transition-all h-0 overflow-hidden ${isActive ? "h-full overflow-visible duration-500 " : ""
          }`}
      >
        <p>{desc}</p>
      </div>
    </div>
  );
};
// const data = [
//   {
//     date: "28 Mar, 2022",
//     material: "Beer Bottles",
//     volume: "90000 Pieces",
//     avgPrice: "₹2000",
//   },
// ];
const columns = [
  {
    name: "Date",
    key: "date",
  },
  {
    name: "Material name",
    key: "material",
    // className: "font-medium text-gray-500 tracking-wider text-sm"
  },
  {
    name: "Volume / Month",
    key: "volume",
    // className: "font-medium text-gray-500 tracking-wider text-sm"
  },

  {
    name: "Avg price",
    key: "avgPrice",
    // className: "font-medium text-gray-500 tracking-wider text-sm"
  },
];

export default function InsightsMaterials({ info = {} }) {
  const [selectedAccordion, setSelectedAccordion] = React.useState(4);
  const [materialData, setMaterialData] = useState([]);
  const [volumeChartData, setVolumeChartData] = useState([
    { label: "", data: [{ date: "", unit: 0 }] },
  ]);
  const [priceChartData, setPriceChartData] = useState([
    { label: "", data: [{ date: "", unit: 0 }] },
  ]);
  const [tickValues, setTickValues] = useState([]);
  const generateTickValues = () => {
    let weekendObj = [];
    let weekend = moment().endOf("week").valueOf();
    weekendObj.push(weekend);
    let dateUpto = 8;
    for (let count = 0; count < dateUpto; count++) {
      weekend = moment(weekend).subtract(dateUpto === 4 ? 7 : 1, "days");
      weekendObj.push(weekend.valueOf());
    }
    setTickValues(weekendObj.reverse());
  };
  useEffect(() => {
    let materialMap = {};
    let volumeData = {};
    let priceData = {};
    if (info.updates && info.updates.length) {
      // eslint-disable-next-line
      let weekendObj = {};
      let weekend = moment().endOf("week").valueOf(); //.format("DD MMM, YYYY");
      // weekendObj[weekend] = 0;
      for (let count = 0; count < 8; count++) {
        weekend = moment(weekend).subtract(7, "days").valueOf(); //.format("DD MMM, YYYY");
        // weekendObj[weekend] = 0;
      }
      weekendObj[weekend] = 0;
      generateTickValues();
      let materialVolData = {};
      let materialPriceData = {};
      let updatedInfo = info.updates;
      // updatedInfo = [...updatedInfo].sort((a, b) => {
      // if(a.date < b.date)
      //     moment.utc(b.updated_at).diff(moment.utc(a.updated_at))
      // })
      updatedInfo.map((update, uIndex) => {
        let label = update.material.name;
        let updatedDate = moment(update.date).format("DD MMM, YYYY");
        if (!materialMap[update.materials_id]) {
          materialMap[update.materials_id] = {
            date: updatedDate,
            material: update.material.name,
            volume: `${parseFloat(numFormatter(update.quantity).toFixed(2))} tons`,
            avgPrice:
              `₹` +
              parseFloat((numFormatter(update.price * 1000)).toFixed(2))

          };
        }
        //volume
        if (!volumeData[update.materials_id]) {
          volumeData[update.materials_id] = {
            label,
            data: [],
            dates: [],
          };
          materialVolData[update.materials_id] = { ...weekendObj };
        }
        if (!volumeData[update.materials_id].dates.includes(updatedDate)) {
          volumeData[update.materials_id].data.push({
            date: updatedDate,
            unit: update.quantity,
          });
          volumeData[update.materials_id].dates.push(updatedDate);
          materialVolData[update.materials_id][moment(update.date).valueOf()] =
            (materialVolData[update.materials_id][
              moment(update.date).valueOf()
            ] || 0) + update.quantity;
        }
        //price
        if (!priceData[update.materials_id]) {
          priceData[update.materials_id] = {
            label,
            data: [],
            dates: [],
          };
          materialPriceData[update.materials_id] = { ...weekendObj };
        }
        if (!priceData[update.materials_id].dates.includes(updatedDate)) {
          priceData[update.materials_id].data.push({
            date: moment(update.date).format("DD MMM, YYYY"),
            unit: update.price * update.quantity,
          });
          priceData[update.materials_id].dates.push(updatedDate);
          materialPriceData[update.materials_id][
            moment(update.date).valueOf()
          ] =
            (materialPriceData[update.materials_id][
              moment(update.date).valueOf()
            ] || 0) + (update.price * update.quantity);
        }
      });
      materialMap = Object.keys(materialMap).map((m) => materialMap[m]);
      // setMaterialData(
      //   [...materialMap].sort((a, b) => a.date.localeCompare(b.date))
      // );
      setMaterialData(
        [...materialMap].sort((a, b) =>
          moment.utc(b.date).diff(moment.utc(a.date))
        )
      );
      let sortedVolumeData = {},
        sortedPriceData = {};
      Object.entries(materialVolData).forEach((val) => {
        sortedVolumeData[val[0]] = {
          data: Object.keys(val[1])
            .map((weekData) => {
              return {
                date: weekData,
                unit: parseFloat((val[1][weekData]).toFixed(2)),
              };
            })
            .filter((cData) => cData)
            .reverse(),
          label: volumeData[val[0]]?.label,
        };
      });
      Object.entries(materialPriceData).forEach((val) => {
        sortedPriceData[val[0]] = {
          data: Object.keys(val[1])
            .map((weekData) => {
              return {
                date: weekData,
                unit: parseFloat((val[1][weekData] ? (val[1][weekData] / materialVolData[val[0]][weekData]) * 1000 : val[1][weekData]).toFixed(2)),
              };
            })
            .filter((cData) => cData)
            .reverse(),
          label: priceData[val[0]]?.label,
        };
      });
      let volumeChartData = Object.keys(sortedVolumeData).map(
        (v) => sortedVolumeData[v]
      );
      let sortedChartData = volumeChartData.map((volume) => {
        return {
          ...volume,
          data: volume.data.sort((a, b) => {
            return a.date - b.date;
          }),
        };
      });

      setVolumeChartData([...sortedChartData]);
      let priceChartData = Object.keys(sortedPriceData).map(
        (v) => sortedPriceData[v]
      );
      let sortedpriceChartData = priceChartData.map((volume) => {
        return {
          ...volume,
          data: volume.data.sort((a, b) => {
            return a.date - b.date;
          }),
        };
      });
      setPriceChartData([...sortedpriceChartData]);
    } else {
      setMaterialData([]);
      setVolumeChartData([{ label: "", data: [{ date: "", unit: 0 }] }]);
      setPriceChartData([{ label: "", data: [{ date: "", unit: 0 }] }]);
    }
  }, [info]);
  return (
    <div className=" mt-4">
      <Accordion
        title={"Material availability"}
        isActive={selectedAccordion === 0}
        desc={
          <>
            <CustomTable
              columns={columns}
              data={materialData}
              boldColumnIndex={1}
              rowClass="font-medium text-gray-500 tracking-wider text-sm"
              small
            />
          </>
        }
        onAccordionClick={() => {
          setSelectedAccordion(0);
        }}
      />
      <Accordion
        title={"Volume tracker"}
        isActive={selectedAccordion === 1}
        desc={
          <div className="h-72">
            <Charts
              chartData={volumeChartData}
              rotateX
              tickValues={tickValues}
            />
          </div>
        }
        onAccordionClick={() => {
          setSelectedAccordion(1);
        }}
      />
      <Accordion
        title={"Price tracker"}
        isActive={selectedAccordion === 2}
        desc={
          <div className="h-72">
            <Charts
              chartData={priceChartData}
              rotateX
              tickValues={tickValues}
              tooltipFormatterIsTon={true}
            />
          </div>
        }
        onAccordionClick={() => {
          setSelectedAccordion(2);
        }}
      />
    </div>
  );
}
